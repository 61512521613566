import React, { useState, useEffect, useCallback } from "react";
import { ReferenceArea, LineChart, Line, AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend, CartesianGrid, ReferenceLine } from "recharts";
import { Box, FormControl, MenuItem, Select, Button, CircularProgress, Typography, Autocomplete, TextField } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import {ReactComponent as NotificationIcon} from "../pages/account/profile/profile_icon/notification-icon.svg";

import {getCryporCurrencyListHome} from "../apihelper/homepage";
import Modal from "react-modal";
import {Card} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Dialog from "@mui/material/Dialog";
import CreatableSelect from "react-select/creatable";
import {useNavigate} from "react-router-dom";
import {useLocation, Link} from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import json2mq from "json2mq";
import {getTotalunit, getFetchdropdown} from "../apihelper/cryptocurrencylist";
import {addToPortfolio, copyToPortfolio} from "../apihelper/portfolio";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import LoginPopUp from "./loginpopup";
import SidebarMenuList from "../includes/sidebarMenuList.jsx";
import Drawer from "@mui/material/Drawer";
import { useMemo } from "react";
import IconButton from "@mui/material/IconButton";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import {BottomNavigation} from "@mui/material";
import {Helmet} from "react-helmet";


const drawerWidth = 292;


const CryptoChart = (props) => {
  const {window1} = props;
  
  const [selectedCrypto, setSelectedCrypto] = useState("BTC");
  const [timeframe, setTimeframe] = useState("Last 7 Days");
  const [metric, setMetric] = useState("RSI");
  const [compareCrypto, setCompareCrypto] = useState("");
  const [chartData, setChartData] = useState([]);
  const [comparisonData, setComparisonData] = useState([]);
  const [cryptos, setCryptos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { crypto } = useParams();
  const [open, setOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [slugdata, setSlugdata] = useState([]);
  const [user_role, setuser_role] = useState("");
  const [slugpopupdata, setSlugpopupdata] = useState({
          slugname: "",
          slug: "",
          units: 0,
      });
  const navigate = useNavigate();
  const location = useLocation();
  const loc = location.pathname;
  const handleClose = () => setOpen(false);
   const mediaQueryVar = useMediaQuery(
          json2mq({
              minWidth: 900,
          })
      );
    const mediaQueryVar2 = useMediaQuery(
        json2mq({
            minWidth: 414,
        })
    );
    const mediaQueryVar3 = useMediaQuery(
      json2mq({
          maxWidth: 365,
      })
  );
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [unit, setUnit] = useState("");
  const [selectedOption, setSelectedOption] = useState([]);
  const [price, setPrice] = useState("");
  const [totalUnits, setTotalUnits] = useState(0);
  const [selectedDate, handleDateChange] = useState("");
  const [Usablewallet, setUsablewallet] = useState("");
  const [portfolioTypeId, setPortfolioTypeId] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dataslug, setdataslug] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [options, setOptions] = useState([]);
  const [buySell, setbuySell] = React.useState("");
  const [severity, setSeverity] = useState("info");
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [msgforUpdatePortfolio, setMsgforUpdatePortfolio] = useState("loading...");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const container = window1 !== undefined ? () => window1().document.body : undefined;
  const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
  const [metricOpen, setMetricOpen] = useState(null);


  const handleMetricToggle = (event) => {
    setMetricOpen(metricOpen ? null : event.currentTarget);
  };

  const handleMetricClose = () => {
    setMetricOpen(null);
  };
  const [metrics2Open, setMetrics2Open] = useState(null);

  const handleMetrics2Toggle = (event) => {
    setMetrics2Open(metrics2Open ? null : event.currentTarget);
  };

  const handleMetrics2Close = () => {
    setMetrics2Open(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
};
  const drawer = (
          <div className="d-flex flex-column h-100" style={{overflowY: "hidden"}}>
              <SidebarMenuList />
          </div>
      );
  const fetchDropdownOptions = async () => {
          const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
  
          getFetchdropdown({
              user_id: userData.user_id,
  
              Token: userData.token,
          }).then((res) => {
              if (res.code === "200") {
                  const newOptions = res.data.map((item) => ({
                      value: item.id,
                      label: item.name,
                  }));
                  setOptions(newOptions);
                  setuser_role(res.user_role);
              } else {
                  console.error("Data fething error");
              }
          });
      };
  const [snackbaropen, setSnackbaropen] = React.useState(false);
      const closesnackbar = (event, reason) => {
          if (reason === "clickaway") {
              return;
          }
  
          setSnackbaropen(false);
      };
  

  const handleAddToPortfolio = (idx) => {
  
          getCryporCurrencyListHome({
              slug: idx,
          }).then((res) => {
              if (res.code === "200") {
  
                  // Update slugdata
                  setSlugdata(res.data);
                  setuser_role(res.user_role);
  
                  // Use res.data directly here
                  const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
                  if (userData) {
                      setSlugpopupdata({
                          slugname: res.data[0].slug,
                          slug: res.data[0].name,
                          units: res.data[0].total_unit,
                          pricetoday: res.data[0].price_today,
                          logo: res.data[0].logo,
                      });
                      setOpen(true);
                  } else {
                      // Save the source in sessionStorage
                      const originalUrl = window.location.pathname + window.location.search;
                      sessionStorage.setItem("originalUrl", originalUrl);
                      sessionStorage.setItem("loginSource", "addToPortfolio");
                      window.history.pushState({}, "", "/login");
                      localStorage.setItem("isPopupManuallyOpened", "true");
                      setLoginModalOpen(true);
                  }
              } else {
                  console.error("Data fetching error");
              }
          });
      };

      const handleLoginClick = () => {
        // Mask the URL by adding /login at the end without navigating
        window.history.pushState({}, "", "/login");
        localStorage.setItem("isPopupManuallyOpened", "true");
        // Open the login modal
        setLoginModalOpen(true);
    };
    const handleModalClose = () => {
        // Close the modal and reset the URL to the welcome page
        setLoginModalOpen(false);
        navigate(location.pathname, {replace: true});
    };
    

  // Fetch Crypto List
  useEffect(() => {
    const fetchCryptos = async () => {
      try {
        const response = await fetch("https://crowdwisdom.live/wp-json/api/v1/getCryptoSymbolName");
    const data = await response.json();

    if (response.ok) {
      // Exclude the selected crypto from options
      const filteredCryptos = data.data.filter(
        (cryptoItem) => cryptoItem.slug.toLowerCase() !== crypto.toLowerCase()
      );

      setCryptos(filteredCryptos);
      console.log("Filtered crypto options:", filteredCryptos);

      let matchedCrypto = data.data.find(
        (cryptoItem) => cryptoItem.slug.toLowerCase() === crypto.toLowerCase()
      );

      if (!matchedCrypto) {
        const normalizedCrypto = crypto.toLowerCase().replace(/-/g, " ");
        matchedCrypto = data.data.find(
          (cryptoItem) => cryptoItem.slug.toLowerCase() === normalizedCrypto
        );
      }

      console.log("Selected crypto:", matchedCrypto);
      if (matchedCrypto) setSelectedCrypto(matchedCrypto.symbol);
   
        }
      } catch (error) {
        console.error("Error fetching cryptos:", error);
      }
    };
    fetchCryptos();
  }, [crypto]);

  const fetchCryptoData = useCallback(
    async (crypto, setData) => {
      if (!crypto) return;
      setLoading(true);
      setError("");
  
      try {
        const response = await fetch("https://crowdwisdom.live/wp-json/api/v1/get_crypto_charts_data", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ crypto_slug: crypto.toLowerCase(), metric, timestamp: Date.now() }),
        });
  
        if (!response.ok) throw new Error("Failed to fetch data");
  
        const data = await response.json();
        const cryptoKey = crypto.toUpperCase();
        console.log("hello response:", data);
  
        if (!data[cryptoKey]) throw new Error("No data available");
  
        const today = new Date();
        const daysToShow = timeframe === "Last 7 Days" ? 7 : 14;
  
        // Generate full X-axis dates (last 14 or 7 days)
        let fullDateRange = [];
        for (let i = daysToShow - 1; i >= 0; i--) {
          const date = new Date();
          date.setDate(today.getDate() - i);
          fullDateRange.push(date.toLocaleDateString("en-US", { month: "short", day: "numeric" }));
        }
  
        // Map the available data, treating 0 as null
        const rawData = data[cryptoKey][metric]?.slice(-daysToShow).map((value, index) => ({
          date: fullDateRange[index], 
          value: value, // Convert 0 to null
        }));
  
        // Merge fullDateRange with available data to ensure all dates are included
        const formattedData = fullDateRange.map(date => 
          rawData.find(d => d.date === date) || { date, value: null }
        );
  
        setData(formattedData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    },
    [metric, timeframe]
  );
  
  
  
  useEffect(() => {
    fetchCryptoData(selectedCrypto, setChartData);
    if (compareCrypto) fetchCryptoData(compareCrypto, setComparisonData);
  }, [selectedCrypto, compareCrypto, metric, fetchCryptoData]); // Added `metric` dependency
  

// Merging Data Without Overwriting State Directly
const [mergedChartData, setMergedChartData] = useState([]);

useEffect(() => {
  if (chartData.length && comparisonData.length) {
    const mergedData = chartData.map((d, i) => ({
      date: d.date,
      value: d.value,
      comparisonValue: comparisonData[i]?.value ?? null, 
      difference: comparisonData[i] ? Math.abs(d.value - comparisonData[i].value) : null,
    }));

    setMergedChartData(mergedData);
  } else {
    setMergedChartData([]); // Reset when switching metric
  }
}, [chartData, comparisonData, metric]); // Added `metric`



const getDynamicYAxisConfig = (data, metricType) => { 
  if (!data.length) return { stepSize: 1, minValue: 0, maxValue: 5, stepCount: 5 };

  const values = data.map((d) => d.value).filter((v) => v !== null && !isNaN(v));
  if (!values.length) return { stepSize: 1, minValue: 0, maxValue: 5, stepCount: 5 };

  let minValue = Math.min(...values);
  let maxValue = Math.max(...values);

  // ✅ Risk scale (-3 to 3, step 0.05) + Only High Risk Zone
  if (metricType === "Risk") {
    return { 
      stepSize: 0.05, 
      minValue: -3, 
      maxValue: 3, 
      stepCount: 121,
      highRiskZone: { min: -3, max: 0, color: "rgba(255, 99, 132, 0.3)" }, // Red Zone
      referenceLine: { value: 0, color: "red", dashArray: "5 5" } // Dashed Red Line
    };
  }

  // ✅ Buy/Sell/Hold scale (-6 to 6, step 0.25)
  if (metricType === "Buy/Sell/Hold") {
    return { stepSize: 0.25, minValue: -6, maxValue: 6, stepCount: 49 };
  }

  // ✅ Fibonacci Handling
  if (metricType === "Fibonacci") {
    if (maxValue < 1) {
      // ✅ Handle very small numbers (e.g., SHIB)
      let stepSize = Math.pow(10, Math.floor(Math.log10(maxValue / 5))); // Smart step
      return { stepSize, minValue: 0, maxValue, stepCount: Math.ceil(maxValue / stepSize) };
    } else if (maxValue / minValue > 10000) {
      // ✅ Handle extreme differences (e.g., BTC vs. SHIB) with Log Scale
      return {
        stepSize: null, // Log Scale Mode
        minValue: Math.max(minValue, 1e-8),
        maxValue,
        scaleType: "log",
      };
    } else {
      // ✅ Normal Case (BTC, ETH, etc.)
      const range = maxValue - minValue || 1;
      let stepSize = Math.pow(10, Math.floor(Math.log10(range / 5))); // Smart step
      return {
        stepSize,
        minValue: Math.floor(minValue / stepSize) * stepSize,
        maxValue: Math.ceil(maxValue / stepSize) * stepSize,
        stepCount: Math.ceil((maxValue - minValue) / stepSize),
      };
    }
  }
  

  // ✅ Default logic
  const range = maxValue - minValue || 5;
  let stepSize = Math.pow(10, Math.floor(Math.log10(range / 5))); // Smart step
  return { stepSize, minValue: Math.floor(minValue / stepSize) * stepSize, maxValue: Math.ceil(maxValue / stepSize) * stepSize, stepCount: Math.ceil((maxValue - minValue) / stepSize) };
};

// Get Y-axis config
const { minValue, maxValue, stepCount, highRiskZone, referenceLine } = getDynamicYAxisConfig(chartData, metric);

// Dynamically adjust Y-axis width
const yAxisWidth = Math.max(60, String(Math.max(Math.abs(minValue), Math.abs(maxValue))).length * 8 + 25);

const renderRiskZones = () => {
  if (metric !== "Risk" || yAxisDomain.length < 2) return null;

  const [minY, maxY] = yAxisDomain;

  return (
    <>
      {/* High Risk (Red) */}
      {minY < 0 && (
        <ReferenceArea
          y1={minY}
          y2={-0.01} // Just below 0
          strokeOpacity={0}
          fill="rgba(255, 0, 0, 0.2)"
          label={{
            value: "Higher Risk",
            position: "center",
            fill: "red",
            fontSize: 14,
            fontWeight: "bold",
          }}
        />
      )}

      {/* Medium Risk (0 to 0.5) — no fill, just blank */}

      {/* Low Risk (Green) */}
      {maxY > 0.5 && (
        <ReferenceArea
          y1={0.51} // Just above 0.5
          y2={maxY}
          strokeOpacity={0}
          fill="rgba(0, 128, 0, 0.2)"
          label={{
            value: "Lower Risk",
            position: "center",
            fill: "green",
            fontSize: 14,
            fontWeight: "bold",
          }}
        />
      )}
    </>
  );
};


const renderPriceOutlookZones = () => {
  if (metric !== "Buy/Sell/Hold") return null;

  return (
    <>
      {/* Sell Zone (-6 to -4) - RED */}
      <ReferenceArea 
        y1={-6} 
        y2={-4} 
        strokeOpacity={0} 
        fill="rgba(255, 0, 0, 0.2)" // Light Red Background
        label={{
          value: "Sell", 
          position: "center", 
          fill: "red", 
          fontSize: 14, 
          fontWeight: "bold"
        }} 
      />

      {/* Buy Zone (4 to 6) - GREEN */}
      <ReferenceArea 
        y1={4} 
        y2={6} 
        strokeOpacity={0} 
        fill="rgba(0, 128, 0, 0.2)" // Light Green Background
        label={{
          value: "Buy", 
          position: "center", 
          fill: "green", 
          fontSize: 14, 
          fontWeight: "bold"
        }} 
      />
    </>
  );
};


//Render Dashed Reference Line
// Render Dashed Reference Line (without label)
const renderReferenceLine = () => {
  if (metric !== "Risk" || !referenceLine) return null;
  return (
    <ReferenceLine 
      y={referenceLine.value} 
      stroke={referenceLine.color} 
      strokeDasharray={referenceLine.dashArray}
    />
  );
};
const riskStepSize = 0.25;

const roundDownToStep = (value, step) =>
  Math.floor(value / step) * step;

const roundUpToStep = (value, step) =>
  Math.ceil(value / step) * step;

const yAxisDomain = (() => {
  const allValues = [
    ...chartData.map((d) => d.value),
    ...(comparisonData?.length ? comparisonData.map((d) => d.value) : []),
  ];

  if (metric === "Risk") {
    const rawMin = Math.min(...allValues, -1);
    const rawMax = Math.max(...allValues, 1);
    const minValue = roundDownToStep(rawMin, riskStepSize);
    const maxValue = roundUpToStep(rawMax, riskStepSize);
    return [parseFloat(minValue.toFixed(2)), parseFloat(maxValue.toFixed(2))];
  } else if (metric === "Buy/Sell/Hold") {
    return [-6, 6];
  }

  return [0, "auto"];
})();
const yAxisTicks = (() => {
  if (metric === "Risk") {
    const [min, max] = yAxisDomain;
    const tickCount = Math.floor((max - min) / riskStepSize) + 1;
    return Array.from({ length: tickCount }, (_, i) =>
      parseFloat((min + i * riskStepSize).toFixed(2))
    );
  } else if (metric === "Buy/Sell/Hold") {
    return [-6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6];
  }

  return undefined;
})();


const yAxisTickCount = Math.max(10, yAxisTicks?.length || 10);



const formatToNonZero1 = (number) => {
  number = parseFloat(number);

  const absNumber = Math.abs(number); // Work with absolute value for formatting

  let formattedValue = 
      absNumber >= 1 ? number.toFixed(1) :
      absNumber >= 0.1 ? number.toFixed(2) :
      absNumber >= 0.01 ? number.toFixed(3) :
      absNumber >= 0.001 ? number.toFixed(4) :
      absNumber >= 0.0001 ? number.toFixed(5) :
      absNumber >= 0.00001 ? number.toFixed(6) :
      absNumber >= 0.000001 ? number.toFixed(7) :
      absNumber >= 0.0000001 ? number.toFixed(8) :
      absNumber >= 0.00000001 ? number.toFixed(9) :
      absNumber >= 0.000000001 ? number.toFixed(10) :
      number.toFixed(1);

  return formattedValue;
};


const yAxisMax = Math.max(
  ...chartData.map((d) => d.value || 0),
  ...comparisonData.map((d) => d.value || 0)
);
const yAxisMin = Math.min(
  ...chartData.map((d) => d.value || 0),
  ...comparisonData.map((d) => d.value || 0)
);

const isRisk = metric === "Risk";
const isBuySellHold = metric === "Buy/Sell/Hold";

// Force Y-axis min to 0 for all metrics except Risk & Buy/Sell/Hold
const fixedMin = isRisk ? -3 : isBuySellHold ? -6 : 0;
const fixedMax = isRisk ? 3 : isBuySellHold ? 6 : yAxisMax;

// Smart step size calculation
const getSmartStepSize = (min, max) => {
  if (isRisk) return 0.5;
  if (isBuySellHold) return 1;

  const range = max - min || 1;
  const base = Math.pow(10, Math.floor(Math.log10(range)));
  const stepOptions = [base / 5, base / 2, base, base * 2, base * 5];

  const minSteps = 10;
  return stepOptions.find((step) => range / step >= minSteps) || base;
};

// Adjust min and max limits
const getAdjustedLimits = (min, max, step) => {
  let adjustedMin = isRisk || isBuySellHold ? min : 0; // Force 0 for all other cases
  let adjustedMax = Math.ceil(max / step) * step;

  return { adjustedMin, adjustedMax };
};

// Compute Y-axis scaling dynamically
const stepSize = getSmartStepSize(fixedMin, fixedMax);

const adjustedMin = chartData.length 
  ? Math.min(-1, Math.min(...chartData.map(d => d.value)))
  : -1;

const adjustedMax = chartData.length 
  ? Math.max(1, Math.max(...chartData.map(d => d.value)))
  : 1;



  const handleChange = async (selectedOption, slug) => {
          if (selectedOption && selectedOption._isNew_) {
              setIsDialogOpen(true);
              setSelectedOption(null);
              setdataslug(slug);
          } else if (!selectedOption) {
              // Handle the case when the selection is cleared
              setSelectedOption(null);
              setShowAlert(false);
              // Perform any other necessary actions
              return;
          } else {
              const selectedValue = selectedOption.value;
              if (selectedValue.includes("-")) {
                  setErrorMessage("Hyphens are not allowed in the option."); // Set error message
                  setShowAlert(true); // Show the alert
                  return; // Abort further processing
              }
              setShowAlert(false);
              setSelectedOption(selectedOption);
              const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
              getTotalunit({
                  user_id: userData.user_id,
                  slug: slug, // Assuming value contains the slug
                  portfolio_type_id: selectedOption.value, // Adjust as needed
                  Token: userData.token,
              }).then((res) => {
                  if (res.code === "200") {
                      setTotalUnits(res.total_uint);
                      setUsablewallet(res.wallet_amount);
                  } else {
                      console.error("Data fething error");
                  }
              });
          }
      };
      useEffect(() => {
          if (open || showModal) {
              fetchDropdownOptions();
          }
      }, [open, showModal]);
      const handleCreate = async (inputValue) => {
          setTotalUnits(0);
          setUsablewallet("");
  
          if (options.find((option) => option.value === inputValue)) {
              return; // Exit early if the option already exists
          }
          const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
          const newOption = {value: inputValue, label: inputValue};
          const newOptions = [...options, newOption]; // Create a new array with the existing options plus the new one
          setOptions(newOptions); // Update the options state
          const createdOption = newOptions.find((option) => option.value == inputValue);
          if (createdOption) {
              handleChange(createdOption);
          }
      };
    const formatToNonZero = (number) => {
            number = parseFloat(number);
    
            if (number >= 1) {
                return number.toFixed(2);
            } else if (number >= 0.1) {
                return number.toFixed(1);
            } else if (number >= 0.01) {
                return number.toFixed(4);
            } else if (number >= 0.001) {
                return number.toFixed(5);
            } else if (number >= 0.0001) {
                return number.toFixed(6);
            } else if (number >= 0.00001) {
                return number.toFixed(7);
            } else if (number >= 0.000001) {
                return number.toFixed(8);
            } else if (number >= 0.0000001) {
                return number.toFixed(9);
            } else if (number >= 0.00000001) {
                return number.toFixed(10);
            } else if (number >= 0.000000001) {
                return number.toFixed(11);
            }
            return number.toFixed(1);
        };
  const handleClick = (buySell) => {
          setSnackbaropen(true);
          setOpen(true);
          setSeverity("info");
          setIsFormSubmitted(false);
          // setSnackbarcolor("success");
          // setSnackbarmessage("Data saved successfully");
          setMsgforUpdatePortfolio("loading...");
          const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
          const portfolioId =
              selectedOption && !isNaN(parseInt(selectedOption.value)) ? parseInt(selectedOption.value) : 0;
          const existingPortfolio = portfolioId > 0 ? "yes" : "no"; // Determine existing_portfolio based on portfolioId
  
          addToPortfolio({
              portfolio_type_id: portfolioId,
              portfolio_name: selectedOption ? selectedOption.label : "", // Ensure selectedOption is defined
  
              existing_portfolio: existingPortfolio,
              user_id: userData.user_id,
              slug: slugpopupdata.slugname,
              unit: unit,
              acquisition_price: price,
              date_time: selectedDate,
              type: buySell,
              Token: userData.token,
          }).then((res) => {
              if (res.code === "200") {
                  // setOpen(false);
                  setSeverity("info");
                  setMsgforUpdatePortfolio(res.message);
                  setPortfolioTypeId(res.portfolio_type_id);
                  setIsFormSubmitted(true);
                  // navigate(
                  //   `/my-portfolio/${res.portfolio_type_id}/${encodeURIComponent(
                  //     selectedOption.label
                  //   )}`
                  // );
              } else {
                  setSeverity("error");
                  // setOpen(true)
                  setMsgforUpdatePortfolio(res.message);
              }
          });
      };    

  const formattedCrypto = crypto.charAt(0).toUpperCase() + crypto.slice(1); 

  const [unreadAlerts, setUnreadAlerts] = useState(0);
    useEffect(() => {
    
                const userId = userData?.user_id || null;
    
                const fetchAlertsCount = async () => {
                  try {
                    const response = await fetch('https://crowdwisdom.live/wp-json/api/v1/crypto_alerts_count', {
                      method: "POST", // Use POST instead of GET
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({ user_id: userId }), // Send user_id in the body
                    });
              
                    if (!response.ok) {
                      throw new Error(`Error: ${response.status}`);
                    }
              
                    const data = await response.json();
                    console.log(data);
                    setUnreadAlerts(data.unread_notifications);    
    
                  } catch (error) {
                    console.error("Failed to fetch alerts:", error);
                  } finally {
                  }
                };
              
                fetchAlertsCount();
              }
              , []);


  return (
<Box
  sx={{
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: mediaQueryVar ? "0px" : "30px 5px", // Ensure padding isn't empty
    backgroundColor: mediaQueryVar ? "#EDF1F6" : "#fff",
    minHeight: "100vh", // Ensures it takes full height without affecting the footer
    overflow: "auto" ,// Ensures only the content scrolls, not the fixed nav,
  }}
>
<Helmet>
        <title>
        {`${formattedCrypto} Trends - Crowdwisdom360`}
        </title>
        
      </Helmet>
      {isLoginModalOpen && (
                      <LoginPopUp
                          isLoginModalOpen={isLoginModalOpen}
                          setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
                          onClose={handleModalClose}
                      />
                  )}
    {mediaQueryVar ? null : (
                        <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end", // Aligns content to the right
                            marginTop: "0px",
                            marginRight: "0px", // Changed from marginLeft to marginRight
                            fontSize: "20px",
                            width: "98%", // Ensures it takes full width for alignment
                        }}
                    >
                      <div className="notification-section position-relative" onClick={() => navigate("/alerts")}>
                  <NotificationIcon className="notification-icon fa-2x" />
                         <span className="badge rounded-pill badge-notification bg-danger position-absolute top-0 start-100 ">
                                 {unreadAlerts}
                           </span>
                                    </div>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="end"
                            onClick={handleDrawerToggle}
                            sx={{ display: { sm: "none" } }}
                        >
                            <img
                                src="/images/menubar.svg"
                                alt="Menu Icon"
                                style={{
                                    width: "24px",
                                    height: "24px",
                                    marginRight: "15px", // Removed extra spacing
                                    marginTop: "10px",
                                }}
                            />
                        </IconButton>
                    </div>
                    
                    )}
    {mediaQueryVar ? (
      <Box sx={{ display: "flex", width: "100%" }}>
  {/* Sidebar Box */}
  <Box
    sx={{
      width: { xs: "100%", sm: drawerWidth }, // Full width on small screens, fixed width on larger screens
      flexShrink: 0,
    }}
  >
    <Drawer
      container={container}
      variant="temporary"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        display: { xs: "block", sm: "none" },
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: drawerWidth,
          backgroundColor: "white",
          color: "#727376",
        },
      }}
    >
      {drawer}
    </Drawer>
    <Drawer
      variant="permanent"
      sx={{
        display: { xs: "none", sm: "block" },
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: drawerWidth,
          backgroundColor: "white",
          color: "#727376",
        },
      }}
      open
    >
      {drawer}
    </Drawer>
  </Box>

  {/* Content Box next to Sidebar */}
  <Box sx={{ flexGrow: 1, width: "100%", p: 2 }}>
    <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
      <Button
        onClick={() => handleAddToPortfolio(selectedCrypto)}
        variant="outlined"
        sx={{
          border: "1px solid #1877F2",
          borderRadius: "48px",
          padding: "5px 12px",
          backgroundColor: "white",
          color: "#1877F2",
          fontSize: "12px",
          height: "33px",
          fontWeight: "500",
          textTransform: "none",
        }}
      >
        Add to Portfolio
      </Button>
      <Button
        variant="contained"
        sx={{
          border: "1px solid #1877F2",
          borderRadius: "48px",
          padding: "5px 12px",
          backgroundColor: "#1877F2",
          color: "#fff",
          fontSize: "12px",
          height: "33px",
          fontWeight: "500",
          textTransform: "none",
        }}
      >
        Go to My Watchlist
      </Button>

      <div 
  className="notification-section position-absolute end-0 me-5" 
  style={{marginRight:"20px"}}
  onClick={() => navigate("/alerts")}
>
  <NotificationIcon className="notification-icon fa-2x" />
  <span className="badge rounded-pill badge-notification bg-danger position-absolute top-0 start-100">
    {unreadAlerts}
  </span>
</div>
    </Box>

    <Box sx={{ display: "flex", gap: 2, mb: 5, marginTop:"30px" }}>
      {/* Timeframe Selector */}
      <FormControl
      sx={{
        minWidth: "auto",
        borderRadius: "10px",
        height: "45px",
        "& .MuiOutlinedInput-notchedOutline": { border: "none" },
      }}
    >
      <Select
        value={timeframe}
        open={Boolean(metricOpen)}
        onClose={handleMetricClose}
        onOpen={handleMetricToggle}
        IconComponent={() => (
          <div onClick={handleMetricToggle} style={{ cursor: "pointer" }}>
            <img
              src={window.constants.asset_path + "/images/dropdown.png"}
              alt="Dropdown"
              style={{ width: "30px", height: "30px", cursor: "pointer" }}
            />
          </div>
        )}
        onChange={(e) => setTimeframe(e.target.value)}
        sx={{
          background:
            "linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%)",
          borderRadius: "10px",
          height: "45px",
          border: "2px solid #1877F2",
          fontWeight: "bold",
          fontSize: "16px",
          padding: "5px 10px",
          minWidth: "auto",
          cursor: "pointer",
        }}
      >
        <MenuItem value="Last 7 Days">Last 7 Days</MenuItem>
        <MenuItem value="Last 14 Days">Last 14 Days</MenuItem>
      </Select>
    </FormControl>
{/* Metric Selector */}
<FormControl
      sx={{
        minWidth: "auto",
        borderRadius: "10px",
        height: "45px",
        "& .MuiOutlinedInput-notchedOutline": { border: "none" },
      }}
      onClick={handleMetrics2Toggle}
    >
      <Select
        value={metric}
        open={Boolean(metrics2Open)}
        onClose={handleMetrics2Close}
        onOpen={handleMetrics2Toggle}
        onChange={(e) => setMetric(e.target.value)} // ✅ Kept unchanged
        sx={{
          background:
            "linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%)",
          borderRadius: "10px",
          height: "45px",
          border: "2px solid #1877F2",
          fontWeight: "bold",
          fontSize: "16px",
          padding: "5px 10px",
          minWidth: "auto",
          cursor: "pointer",
        }}
        IconComponent={() => (
          <div onClick={handleMetrics2Toggle} style={{ cursor: "pointer" }}>
            <img
              src={window.constants.asset_path + "/images/dropdown.png"}
              alt="Dropdown"
              style={{ width: "30px", height: "30px", cursor: "pointer" }}
            />
          </div>
        )}
      >
        <MenuItem value="RSI">RSI</MenuItem>
<MenuItem value="Social Media Score">Retail Sentiment</MenuItem>
<MenuItem value="Risk">Risk (Sharpe Ratio)</MenuItem>
<MenuItem value="Influencer Sentiment">Influencer Sentiment</MenuItem>
<MenuItem value="Buy/Sell/Hold">Price Outlook</MenuItem>
      </Select>
    </FormControl>


      {/* Compare Crypto Selector (Dynamic) */}
      {metric !== "Fibonacci" && (
  <Autocomplete
    options={cryptos.map((crypto) => crypto.symbol)}
    value={compareCrypto || ""} // Ensure empty value when nothing is selected
    onChange={(event, newValue) => setCompareCrypto(newValue || "")}
    open={dropdownOpen}
    onOpen={() => setDropdownOpen(true)}
    onClose={() => setDropdownOpen(false)}
    sx={{
      "& .MuiAutocomplete-inputRoot": {
        padding: "0px !important",
        cursor: "pointer",
      },
    }}
    renderInput={(params) => (
      <TextField
        {...params}
        variant="outlined"
        onClick={() => setDropdownOpen(true)}
        placeholder="Compare Crypto"
        sx={{
          backgroundColor: "#1877f2",
          borderRadius: "48px",
          width: "250px",
          height: "45px",
          "& .MuiOutlinedInput-root": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "2px 8px !important",
            "& fieldset": { border: "none" },
            cursor: "pointer",
          },
          "& .MuiInputBase-input": {
            fontWeight: "bold",
            fontSize: "16px",
            padding: "0px 5px !important",
            textAlign: "left",
            flexGrow: 1,
            marginLeft: "5px",
            cursor: "pointer",
            color: compareCrypto ? "white" : "white",
          },
        }}
        InputProps={{
          ...params.InputProps,
          startAdornment:
            compareCrypto ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  padding: "0 8px",
                }}
              >
                <img
                  src={window.constants.asset_path + "/images/arrowdown-white.png"}
                  alt="Remove"
                  style={{ cursor: "pointer", width: 20, height: 20 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setCompareCrypto("");
                    setComparisonData([]); // Clear comparison data
                    setMergedChartData([]);
                  }}
                />
              </Box>
            ) : null,
          endAdornment: (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "40px",
                marginRight: "5px",
                cursor: "pointer",
              }}
              onClick={() => setDropdownOpen(true)}
            >
              <img
                src={window.constants.asset_path + "/images/dropdown.png"}
                alt="Dropdown"
                style={{
                  cursor: "pointer",
                  width: 40,
                  height: 40,
                }}
              />
            </Box>
          ),
        }}
      />
    )}
  />
)}





    </Box>

    {/* Chart Section */}


    <Box sx={{ height: 500, width: "auto",
    backgroundColor: "white",
    border: "2px solid #7e7ef7",
    borderRadius: "24px",
    padding: "16px",
  }}>
    {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "250px" }}>
          <CircularProgress style={{marginTop:"2%"}}/>
        </Box>
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <ResponsiveContainer width="100%" height={450}>
        <LineChart data={mergedChartData.length ? mergedChartData : chartData}>
          <CartesianGrid 
            vertical={false} 
            horizontal={true} 
            stroke="rgba(0, 0, 0, 0.3)" 
            strokeWidth={0.3} 
          />
          <XAxis
  dataKey="date"
  tick={{ fill: "#4A4A4A", fontSize: "12px" }}
  tickLine={false}
  axisLine={{ stroke: "rgba(0, 0, 0, 0.1)" }}
  angle={-45}
  textAnchor="end"
  height={60}
/>
<YAxis
  domain={yAxisDomain}
  ticks={yAxisTicks}
  tickCount={yAxisTickCount}
  tick={{ fill: "#4A4A4A", fontSize: "12px", dy: 4 }}
  tickLine={false}
  axisLine={{ stroke: "rgba(0, 0, 0, 0.1)" }}
  tickMargin={10}
  allowDataOverflow
  tickFormatter={(value) =>
    value === 0 ? "0" : value !== undefined ? formatToNonZero1(value) : ""
  }
  label={{
    value:
      metric === "Buy/Sell/Hold"
        ? "Price Outlook"
        : metric === "Social Media Score"
        ? "Retail Sentiment"
        : metric,
    angle: -90,
    position: "insideLeft",
    dx: -6,
    dy: 0,
    offset: 10,
    style: { fontSize: "14px", textAnchor: "middle", fill: "#4A4A4A" },
  }}
/>


{/* Risk Zones (For Risk Graph) */}
{metric === "Risk" && renderRiskZones()}

{/* Price Outlook Zones (For Buy/Sell/Hold Graph) */}
{metric === "Buy/Sell/Hold" && renderPriceOutlookZones()}






<Tooltip
  formatter={(value) => formatToNonZero1(value)}
  contentStyle={{
    backgroundColor: "#ffffff",
    borderColor: "#333",
    borderWidth: 1,
    fontFamily: "Arial",
  }}
  itemStyle={{ color: "#4A4A4A" }}
  allowEscapeViewBox={{ x: false, y: false }}
  cursor={{ strokeDasharray: "3 3" }}
  trigger="hover"
/>



      
         
          <Legend
            verticalAlign="top"
            wrapperStyle={{ paddingBottom: 10 }}
            iconType="square"
            formatter={(value) => {
              if (value === "value") return <span style={{ color: "#7e7ef7" }}>{selectedCrypto}</span>;
              if (value === "comparisonValue" && compareCrypto) return <span style={{ color: "#ff4c4c" }}>{compareCrypto}</span>;
              return value;
            }}
          />
          
          <Line
        type="monotone"
        dataKey="value"
        stroke="#7e7ef7"
        strokeWidth={2}
        dot={false}
        name={selectedCrypto}
        connectNulls={true} // Ensures missing values don't create dips to zero
      />
      
          
      {compareCrypto && (
                <Line
                  type="monotone"
                  dataKey="comparisonValue"
                  stroke="#ff4c4c"
                  strokeWidth={2}
                  dot={false}
                  name={compareCrypto}
                  connectNulls={true} // Ensures missing values don't create dips to zero
                />
              )}
        </LineChart>
      </ResponsiveContainer>
      
      )}
</Box>


  </Box>
</Box>
    ):(
      <Box sx={{ display: "flex", width: "100%", flexDirection: { xs: "column", sm: "row" }, paddingBottom:"20vw" }}>
  {/* Sidebar */}
  <Box sx={{ width: { xs: "100%", sm: drawerWidth }, flexShrink: 0 }}>
    <Drawer
      container={container}
      variant="temporary"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      ModalProps={{ keepMounted: true }}
      sx={{ display: { xs: "block", sm: "none" }, "& .MuiDrawer-paper": { width: drawerWidth } }}
    >
      {drawer}
    </Drawer>
    <Drawer
      variant="permanent"
      sx={{ display: { xs: "none", sm: "block" }, "& .MuiDrawer-paper": { width: drawerWidth } }}
      open
    >
      {drawer}
    </Drawer>
  </Box>

  {/* Main Content */}
  <Box sx={{ flexGrow: 1, width: "100%", p: 1, marginTop:"-40px" }}>  
    {/* Buttons on Same Line */}
    <Box sx={{ display: "flex", gap: 1, mb: 2, flexWrap: "wrap" }}>
      <Button
        onClick={() => handleAddToPortfolio(selectedCrypto)}
        variant="outlined"
        sx={{ border: "1px solid #1877F2",
          borderRadius: "48px",
          padding: "5px 12px",
          backgroundColor: "white",
          color: "#1877F2",
          fontSize: "12px",
          height: "33px",
          fontWeight: "500",
          textTransform: "none", }}
      >
        Add to Portfolio
      </Button>
      <Button
        variant="contained"
        sx={{ border: "1px solid #1877F2",
          borderRadius: "48px",
          padding: "5px 12px",
          backgroundColor: "#1877F2",
          color: "#fff",
          fontSize: "12px",
          height: "33px",
          fontWeight: "500",
          textTransform: "none", }}
      >
        Go to My Watchlist
      </Button>
    </Box>

    {/* Filters on Same Line */}
    <Box sx={{ display: "flex", gap: "6px", flexWrap: "wrap", marginTop:"40px" }}> 
  <FormControl
    sx={{
      width: { xs: "auto", sm: "140px" }, // Reduced width further
      minWidth: "auto",
      maxWidth: "100%",
      background: "linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%)",
      borderRadius: "10px",
      height: "38px", // Slightly reduced height
      border: "1.8px solid #1877F2",
      fontSize: "14px",
      fontWeight: "bold",
    }}
  >
    <Select
      value={timeframe}
      open={metricOpen}
      onClose={handleMetricClose}
      onOpen={handleMetricToggle}
      onChange={(e) => setTimeframe(e.target.value)}
      displayEmpty
      IconComponent={() => (
        <div onClick={handleMetricToggle} style={{ cursor: "pointer" }}>
          <img
            src={window.constants.asset_path + "/images/dropdown.png"}
            alt="Dropdown"
            style={{ width: "16px", height: "20px", marginLeft: "2px" }} // Reduced size & margin
          />
        </div>
      )}
      sx={{
        borderRadius: "10px",
        height: "38px",
        fontWeight: "bold",
        padding: "0 4px", // Reduced padding
        minWidth: "auto",
        maxWidth: "100%",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      <MenuItem value="Last 7 Days" sx={{ fontWeight: "bold" }}>
        Last 7 Days
      </MenuItem>
      <MenuItem value="Last 14 Days" sx={{ fontWeight: "bold" }}>
        Last 14 Days
      </MenuItem>
    </Select>
  </FormControl>

  <FormControl
    sx={{
      width: { xs: "auto", sm: "140px" }, // Matching reduced width
      minWidth: "auto",
      maxWidth: "100%",
      background: "linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%)",
      borderRadius: "10px",
      height: "38px",
      border: "1.8px solid #1877F2",
      fontSize: "14px",
      fontWeight: "bold",
      cursor: "pointer",
    }}
    onClick={handleMetrics2Toggle}
  >
    <Select
      value={metric}
      open={metrics2Open}
      onClose={handleMetrics2Close}
      onOpen={handleMetrics2Toggle}
      onChange={(e) => setMetric(e.target.value)}
      displayEmpty
      sx={{
        borderRadius: "10px",
        height: "38px",
        fontWeight: "bold",
        padding: "0 4px", // Reduced padding
        minWidth: "auto",
        maxWidth: "100%",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
      }}
      IconComponent={() => (
        <div onClick={handleMetrics2Toggle} style={{ cursor: "pointer" }}>
          <img
            src={window.constants.asset_path + "/images/dropdown.png"}
            alt="Dropdown"
            style={{ width: "16px", height: "20px", marginLeft: "2px" }} // Reduced size & margin
          />
        </div>
      )}
    >
      <MenuItem value="RSI">RSI</MenuItem>
<MenuItem value="Social Media Score">Retail Sentiment</MenuItem>
<MenuItem value="Risk">Risk (Sharpe Ratio)</MenuItem>
<MenuItem value="Influencer Sentiment">Influencer Sentiment</MenuItem>
<MenuItem value="Buy/Sell/Hold">Price Outlook</MenuItem>
    </Select>
  </FormControl>
</Box>


    {/* Compare Crypto - Centered */}
    
    <Box sx={{ display: "flex", justifyContent: "right", mb: 2, marginTop:"40px" }}>
    {metric !== "Fibonacci" && (
    <Autocomplete
  options={cryptos.map((crypto) => crypto.symbol)}
  value={compareCrypto || ""} // Ensure empty value when nothing is selected
  onChange={(event, newValue) => setCompareCrypto(newValue || "")}
  open={dropdownOpen}
  onOpen={() => setDropdownOpen(true)}
  onClose={() => setDropdownOpen(false)}
  sx={{
    "& .MuiAutocomplete-inputRoot": {
      padding: "0px !important",
      cursor: "pointer",
    },
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      variant="outlined"
      onClick={() => setDropdownOpen(true)}
      placeholder="Compare Crypto"
      sx={{
        backgroundColor: "#1877f2",
        borderRadius: "48px",
        width: "250px",
        height: "45px",
        "& .MuiOutlinedInput-root": {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "2px 8px !important",
          "& fieldset": { border: "none" },
          cursor: "pointer",
        },
        "& .MuiInputBase-input": {
          fontWeight: "bold",
          fontSize: "16px",
          padding: "0px 5px !important",
          textAlign: "left",
          flexGrow: 1,
          marginLeft: "10px",
          cursor: "pointer",
          color: compareCrypto ? "white" : "white", // Light gray when empty
        },
      }}
      InputProps={{
        ...params.InputProps,
        startAdornment:
          compareCrypto ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                padding: "0 8px",
              }}
            >
              <img
                src={window.constants.asset_path + "/images/arrowdown-white.png"}
                alt="Remove"
                style={{ cursor: "pointer", width: 20, height: 20 }}
                onClick={(e) => {
                  e.stopPropagation();
                  setCompareCrypto("");
                  setComparisonData([]); // Clear comparison data
                  setMergedChartData([]);
                }}
              />
            </Box>
          ) : null,
        endAdornment: (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "40px",
              marginRight: "5px",
              cursor: "pointer",
            }}
            onClick={() => setDropdownOpen(true)}
          >
            <img
              src={window.constants.asset_path + "/images/dropdown.png"}
              alt="Dropdown"
              style={{
                cursor: "pointer",
                width: 40,
                height: 40,
              }}
            />
          </Box>
        ),
      }}
    />
  )}
/>
)}


    </Box>

    {/* Chart Section - Improved Mobile Visibility */}
    <Box
  sx={{
    height: 400,
    width: "100%", 
    backgroundColor: "white",
    border: "2px solid #7e7ef7",
    borderRadius: "16px",
    padding: "12px",
  }}
>
{loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "250px" }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
  <ResponsiveContainer width="100%" height={350}>
    <LineChart data={mergedChartData.length ? mergedChartData : chartData} margin={{ left: 10, right: 5 }}>
      <CartesianGrid vertical={false} horizontal stroke="rgba(0, 0, 0, 0.3)" strokeWidth={0.3} />
      
      <XAxis
  dataKey="date"
  tick={{ fill: "#4A4A4A", fontSize: "10px" }}
  tickLine={false}
  axisLine={{ stroke: "rgba(0, 0, 0, 0.1)" }}
  angle={-45}
  textAnchor="end"
  height={60}
  interval={0} // This forces ALL ticks to be shown
/>

<YAxis
  domain={yAxisDomain}
  ticks={yAxisTicks}
  tickCount={yAxisTickCount}
  tick={{ fill: "#4A4A4A", fontSize: "12px", dy: 4 }}
  tickLine={false}
  axisLine={{ stroke: "rgba(0, 0, 0, 0.1)" }}
  tickMargin={10}
  allowDataOverflow
  tickFormatter={(value) =>
    value === 0 ? "0" : value !== undefined ? formatToNonZero1(value) : ""
  }
  label={{
    value:
      metric === "Buy/Sell/Hold"
        ? "Price Outlook"
        : metric === "Social Media Score"
        ? "Retail Sentiment"
        : metric,
    angle: -90,
    position: "insideLeft",
    dx: -6,
    dy: 0,
    offset: 10,
    style: { fontSize: "14px", textAnchor: "middle", fill: "#4A4A4A" },
  }}
/>

<XAxis 
  tick={{ fontSize: "10px" }} 
  allowDuplicatedCategory={false} // Ensuring full x-axis visibility
  tickCount={5} // Keeping x-axis labels readable
/>



{/* Risk Zones (For Risk Graph) */}
{metric === "Risk" && renderRiskZones()}

{/* Price Outlook Zones (For Buy/Sell/Hold Graph) */}
{metric === "Buy/Sell/Hold" && renderPriceOutlookZones()}

<Tooltip
  formatter={(value) => formatToNonZero1(value)}
  contentStyle={{
    backgroundColor: "#ffffff",
    borderColor: "#333",
    borderWidth: 1,
    fontFamily: "Arial",
  }}
  itemStyle={{ color: "#4A4A4A" }}
  allowEscapeViewBox={{ x: false, y: false }}
  cursor={{ strokeDasharray: "3 3" }}
  trigger="hover"
/>


      <Legend
        verticalAlign="top"
        wrapperStyle={{ paddingBottom: 8 }}
        iconType="square"
        formatter={(value) => {
          if (value === "value") return <span style={{ color: "#7e7ef7" }}>{selectedCrypto}</span>;
          if (value === "comparisonValue" && compareCrypto) return <span style={{ color: "#ff4c4c" }}>{compareCrypto}</span>;
          return value;
        }}
      />
      
      <Line type="monotone" dataKey="value" stroke="#7e7ef7" strokeWidth={2} dot={false} name={selectedCrypto} />
      
      {compareCrypto && (
        <Line type="monotone" dataKey="comparisonValue" stroke="#ff4c4c" strokeWidth={2} dot={false} name={compareCrypto} />
      )}
    </LineChart>
  </ResponsiveContainer>
      )}
</Box>

  </Box>
</Box>

    )}

<Modal
                isOpen={open}
                onRequestClose={handleClose}
                overlayClassName={{
                    overlay: {
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black overlay
                        backdropFilter: "blur(5px)", // Optional: Add a blur effect to the overlay
                    },
                }}
                className={{
                    content: {
                        position: "relative",
                        backgroundColor: "white", // Set background for inner popup
                        padding: "20px", // Add padding for content
                        borderRadius: "8px", // Rounded corners
                        maxWidth: mediaQueryVar ? "100%" : "100%", // Set a max width for popup
                        margin: "0", // Center the popup horizontally
                        boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2)", // Optional: Popup shadow
                    },
                }}
            >
                <div className="CardOpener" style={{overflow: "hidden"}}>
                    {isFormSubmitted ? (
                        <div
                            style={{
                                position: "fixed", // Fix the popup to the top of the viewport
                                top: 0, // Align it to the top
                                left: 0,
                                width: "100%",
                                height: "100vh",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "rgba(0, 0, 0, 0.5)", // Optional: darken the background for emphasis
                                zIndex: 1000, // High z-index to overlay other elements
                            }}
                        >
                            <Card className="card__container">
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        flexDirection: "column",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <CheckCircleOutlineIcon sx={{fontSize: 50, mr: 1}} />
                                    </div>
                                    <Typography
                                        severity="success"
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        Portfolio added successfully!
                                    </Typography>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-evenly",
                                            marginTop: "10px",
                                            width: "100%",
                                        }}
                                    >
                                        <Button
                                            onClick={() => {
                                                setIsFormSubmitted(false);
                                                setOpen(false);
                                                setUnit("");
                                                setSelectedOption("");
                                                setPrice("");
                                                setTotalUnits(0);
                                                handleDateChange("");
                                                setUsablewallet("");
                                            }}
                                            color="primary"
                                            style={{
                                                backgroundColor: "rgba(67, 97, 238, 0.15)",
                                                borderRadius: "6px",
                                                border: "none",
                                                textTransform: "none",
                                                borderStyle: "solid",
                                                borderColor: "rgba(67, 97, 238, 0.15)",
                                                borderWidth: "1px",
                                                color: "#4361ee",
                                                whiteSpace: "nowrap",
                                                float: "inline-end",
                                                width: mediaQueryVar === true ? "150px" : "100px",
                                                height: mediaQueryVar === false ? "30px" : "",
                                                fontSize: mediaQueryVar === false ? "10px" : "11px",
                                            }}
                                        >
                                            Add More Cryptos
                                        </Button>
                                        <Button
                                            onClick={() =>
                                                navigate(
                                                    `/my-portfolio/${portfolioTypeId}/${encodeURIComponent(
                                                        selectedOption.label.replace(/\s+/g, "-")
                                                    )}`
                                                )
                                            }
                                            variant="outlined"
                                            style={{
                                                backgroundColor: "rgba(67, 97, 238, 0.15)",
                                                borderRadius: "6px",
                                                border: "none",
                                                textTransform: "none",
                                                borderStyle: "solid",
                                                borderColor: "rgba(67, 97, 238, 0.15)",
                                                borderWidth: "1px",
                                                color: "#4361ee",
                                                whiteSpace: "nowrap",
                                                float: "inline-end",
                                                width: mediaQueryVar === true ? "100px" : "100px",
                                                height: mediaQueryVar === false ? "30px" : "",
                                                fontSize: mediaQueryVar === false ? "10px" : "11px",
                                            }}
                                            color="primary"
                                        >
                                            View Portfolio
                                        </Button>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    ) : (
                        <Dialog
                            PaperProps={{
                                sx: {
                                    position: mediaQueryVar ? "static" : "fixed",
                                    bottom: mediaQueryVar ? "" : 0,
                                    left: mediaQueryVar ? "" : 0,
                                    right: mediaQueryVar ? "" : 0,
                                    marginLeft: mediaQueryVar ? "" : "0px",
                                    marginRight: mediaQueryVar ? "" : "0px",
                                    marginTop: "1%",
                                    maxWidth: "100%",
                                    minHeight: mediaQueryVar ? "60%" : "", // Adjust max height if needed
                                    borderRadius: mediaQueryVar ? "24px" : "24px 24px 0 0",
                                    marginBottom: mediaQueryVar ? "" : "0px",
                                    padding: mediaQueryVar ? "10px" : "1px",
                                    alignItems: "center",
                                    overflow: "scroll",
                                    "&::-webkit-scrollbar": {
                                        display: "none",
                                    },
                                    "-ms-overflow-style": "none",
                                    "scrollbar-width": "none", // Ensure no scrollbar
                                },
                            }}
                            open={open}
                            onClose={handleClose}
                        >
                            <div
                                style={{
                                    padding: "20px",
                                    width: mediaQueryVar ? "400px" : "100%",
                                    margin: "0",
                                    marginTop: "-5px",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        marginBottom: "20px",
                                        borderBottom: "1px #E3E3E3 solid",
                                    }}
                                >
                                    <Typography
                                        style={{
                                            fontWeight: "500",
                                            fontSize: "20.767px",
                                            paddingLeft: "100px",
                                        }}
                                        gutterBottom
                                    >
                                        Add To Portfolio
                                    </Typography>

                                    <div className="primaryButton">
                                        <img
                                            className="frameIcon"
                                            alt="frameIcon"
                                            onClick={handleClose}
                                            src={window.constants.asset_path + "/images/frame17.svg"}
                                            style={{
                                                background: "#F3F3F3",
                                                width: "25px",
                                                height: "25px",
                                            }}
                                        />
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        marginBottom: "5px",
                                        marginLeft: mediaQueryVar === false ? "10px" : "",
                                    }}
                                >
                                    <Typography
                                        style={{
                                            fontSize: "13.845px",
                                            fontWeight: "500",
                                            marginBottom: "1px",
                                            color: "black",
                                            marginTop: "-8px",
                                        }}
                                    >
                                        Select Portfolio
                                    </Typography>
                                    <div style={{marginTop: "5px"}}>
                                        <CreatableSelect
                                            isClearable
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    borderRadius: "12px",
                                                    border: "1px solid #1877F2",
                                                    boxShadow: "none",
                                                    minHeight: "40px",
                                                    "&:hover": {
                                                        borderRadius: "12px",
                                                        border: "2px solid #1877F2",
                                                    },
                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    borderRadius: "8px",
                                                }),
                                                placeholder: (provided) => ({
                                                    ...provided,
                                                    color: "",
                                                }),
                                                dropdownIndicator: (provided) => ({
                                                    ...provided,
                                                    color: "#1877F2",
                                                    border: "1px solid #1877F2",
                                                    padding: "5px",
                                                    margin: "8px",
                                                    borderRadius: "3.786px",
                                                    "&:hover": {
                                                        color: "#1877F2",
                                                    },
                                                }),
                                                indicatorSeparator: (provided) => ({
                                                    display: "none",
                                                }),
                                            }}
                                            placeholder=""
                                            onChange={(options) => handleChange(options, slugpopupdata.slugname)}
                                            onCreateOption={handleCreate}
                                            options={[
                                                {
                                                    value: "",
                                                    label: "Create new portfolio",
                                                    _isNew_: true,
                                                }, // Dynamic "create new option" item
                                                ...options, // Existing options
                                            ]}
                                            value={selectedOption}
                                        />
                                    </div>
                                    {showAlert && (
                                        <Alert severity="error" sx={{marginBottom: "10px"}}>
                                            {errorMessage}
                                        </Alert>
                                    )}
                                </div>
                                <Typography
                                    style={{
                                        fontSize: "13.845px",
                                        fontWeight: "500",
                                        color: "#000",
                                        marginTop: "10px",
                                        marginBottom: "5px",
                                    }}
                                >
                                    Usable Wallet Value: {Usablewallet}
                                </Typography>
                                <div
                                    style={{
                                        padding: mediaQueryVar ? "8px" : "5px",
                                        background: "#F3F3F3",
                                        borderRadius: "8px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        marginBottom: "5px",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            flex: 0,
                                            minWidth: 0,
                                        }}
                                    >
                                        <img
                                            style={{
                                                marginRight: "10px",
                                                border: "2px solid #000",
                                                borderRadius: "19px",
                                                height: "27.69px",
                                                width: "27.69px",
                                            }}
                                            alt="logo"
                                            src={slugpopupdata.logo}
                                        />
                                        <div style={{display: "flex", flexDirection: "column"}}>
                                            <Typography
                                                sx={{
                                                    fontWeight: "500",
                                                    marginBottom: "0px",
                                                    fontSize: "14px",
                                                    color: "#7F7F80",
                                                }}
                                            >
                                                {slugpopupdata.slugname}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            marginLeft: "20%",
                                            flex: 1,
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontWeight: "500",
                                                marginBottom: "4px",
                                                fontSize: "13px",
                                                color: "#7F7F80",
                                            }}
                                        >
                                            {" "}
                                            Price
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: "12.114px",
                                                fontWeight: "500",
                                                color: "black",
                                                margin: "0",
                                                marginLeft: "5%",
                                            }}
                                        >
                                            {slugpopupdata.pricetoday}
                                        </Typography>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            marginLeft: "20px",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontWeight: "500",
                                                marginBottom: "4px",
                                                fontSize: "13px",
                                                color: "#7F7F80",
                                            }}
                                        >
                                            {" "}
                                            Units
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: "12.114px",
                                                fontWeight: "500",
                                                color: "black",
                                                margin: "0",
                                            }}
                                        >
                                            {totalUnits}
                                        </Typography>
                                    </div>
                                </div>
                                <div className="card__container__row3">
                                    <div style={{marginLeft: "-5px"}}>
                                        <p style={{fontSize: "14px", fontWeight: "500"}}>Enter number of Units</p>
                                        <input
                                            style={{
                                                backgroundColor: "white",
                                                color: "black",
                                                padding: "20px",
                                                border: "1px solid #1877F2",
                                                borderRadius: "10.384px",
                                                width: mediaQueryVar ? "350px" : "100%",
                                                height: "45px",
                                                margin: 0,
                                            }}
                                            min="1"
                                            max="10"
                                            type="number"
                                            placeholder=""
                                            value={unit}
                                            onChange={(e) => setUnit(e.target.value)}
                                        ></input>
                                    </div>
                                    <div>
                                        $
                                        {formatToNonZero(
                                            unit *
                                                parseFloat(
                                                    (slugpopupdata.pricetoday || "")
                                                    .replace(/^\$/, "") // Remove the dollar sign
                                                    .replace(/,/g, "") // Remove commas
                                                )
                                        )}
                                    </div>
                                    {user_role === "superuser" && (
                                        <>
                                            <div className="card__container__row3__r2">
                                                <div style={{marginLeft: "-5px"}}>
                                                    <p style={{fontSize: "14px", fontWeight: "500"}}>
                                                        Buy/Sell Price($)
                                                    </p>
                                                    <input
                                                        style={{
                                                            backgroundColor: "white",
                                                            color: "black",
                                                            padding: "20px",
                                                            border: "1px solid #1877F2",
                                                            borderRadius: "10.384px",
                                                            width: "345px",
                                                            height: "45px",
                                                        }}
                                                        className="card__container__row3__r2__input"
                                                        min="1"
                                                        max="10"
                                                        type="number"
                                                        placeholder="Enter Price"
                                                        value={price}
                                                        onChange={(e) => setPrice(e.target.value)}
                                                    ></input>
                                                </div>
                                            </div>
                                            <div style={{marginBottom: "10px", marginLeft: "-5px"}}>
                                                <p style={{fontSize: "14px", fontWeight: "500"}}>Date</p>
                                                <TextField
                                                    type="date"
                                                    value={selectedDate}
                                                    onChange={(e) => handleDateChange(e.target.value)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    sx={{
                                                        "& .MuiOutlinedInput-root": {
                                                            borderRadius: "10.384px",
                                                            border: "1px solid #1877F2",
                                                            boxShadow: "none",
                                                            width: "190%",
                                                            height: "45px",
                                                        },
                                                    }}
                                                />
                                            </div>
                                        </>
                                    )}
                                    <div className="card__container__row3__r4" style={{marginTop: "10px"}}>
                                        <Button
                                            className={buySell === "buy" ? "buttonToggleButActive" : "buttonToggleBuy"}
                                            value="Buy"
                                            style={{
                                                lineHeight: "20px",
                                                width: mediaQueryVar ? "159px" : "130px",
                                                borderRadius: "27px",
                                                border: "1px solid #1877F2",
                                                padding: mediaQueryVar ? "15px 85px" : "13px 75px",
                                                marginLeft: "-6px",
                                                backgroundColor: buySell === "buy" ? "" : "#1877F2",
                                                color: buySell === "buy" ? "" : "white",
                                                textTransform: "none",
                                                fontSize: "18px",
                                                fontWeight: "700",
                                            }}
                                            onClick={() => handleClick("buy")}
                                        >
                                            Buy
                                        </Button>
                                        <Button
                                            className={
                                                buySell === "sell" ? "buttonToggleSellActive" : "buttonToggleSell"
                                            }
                                            value="sell"
                                            style={{
                                                lineHeight: "20px",
                                                width: mediaQueryVar ? "159px" : "130px",
                                                borderRadius: "27px",
                                                border: "1px solid #1877F2",
                                                marginLeft: "10px",
                                                padding: mediaQueryVar ? "15px 85px" : "13px 75px",
                                                backgroundColor: buySell === "sell" ? "" : "#1877F2",
                                                color: buySell === "sell" ? "" : "white",
                                                textTransform: "none",
                                                fontSize: "18px",
                                                fontWeight: "700",
                                            }}
                                            onClick={() => handleClick("sell")}
                                        >
                                            Sell
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    )}
                </div>
            </Modal>
            {mediaQueryVar === false && (
                            <div
                                style={{
                                    position: "fixed",
                                    bottom: "0",
                                    backgroundColor: "white",
                                    width: "100%",
                                    zIndex: "100",
                                    marginBottom: "0px",
                                    marginLeft: "-5px",
                                    padding: "2px 0px"
                                    
                                }}
                            >
                                <BottomNavigation showLabels>
                                    <BottomNavigationAction
                                        label="Top Portfolios"
                                        onClick={() => navigate("/portfolio-gallery")}
                                        icon={
                                            <img
                                                src={
                                                    loc === "/portfolio-gallery"
                                                        ? "/images/dashboardS.svg"
                                                        : "/images/dashboardU.svg"
                                                }
                                                width={"20px"}
                                                height={"20px"}
                                                alt="gallery"
                                            />
                                        }
                                    />
                                    <BottomNavigationAction
                                        label="Crypto Screener"
                                        onClick={() => navigate("/crypto-screener")}
                                        icon={
                                            <img
                                                src={
                                                    loc === "/crypto-screener" ? "/images/portfolioS.svg" : "/images/portfolioU.svg"
                                                }
                                                width={"20px"}
                                                height={"20px"}
                                                alt="screener"
                                            />
                                        }
                                    />
                                    <BottomNavigationAction
                                        label="Portfolio Audit"
                                        onClick={() => {
                                            if (userData) {
                                                navigate("/subscriptions/screener360");
                                            } else {
                                                const originalUrl = "/subscriptions/screener360";
                                                sessionStorage.setItem("originalUrl", originalUrl);
                                                handleLoginClick();
                                            }
                                        }}
                                        icon={
                                            <img
                                                src={
                                                    loc === "/subscriptions/screener360"
                                                        ? "/images/dollarS.svg"
                                                        : "/images/dollarU.svg"
                                                }
                                                width={24}
                                                height={24}
                                                alt="Icon"
                                                style={{marginBottom: "-3px"}}
                                            />
                                        }
                                    />
                                </BottomNavigation>
                            </div>
                        )}
    </Box>
  
  );
};

export default CryptoChart;
