import React from "react";
import Box from "@mui/material/Box";
import VerifiedIcon from '@mui/icons-material/Verified';
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import LoginPopUp from "../loginpopup.js";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import { ButtonGroup,BottomNavigation,Paper,Chip } from "@mui/material";
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import AddIcon from '@mui/icons-material/Add';
import BarChartIcon from '@mui/icons-material/BarChart';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import CreatableSelect from "react-select/creatable";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Table from "@mui/material/Table";
import { Tabs, Tab } from '@mui/material';
import { Helmet } from 'react-helmet';
import TableRow from "@mui/material/TableRow";
import { Button, Grid, TextField, Card, Checkbox } from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import "../home/home.css";
import json2mq from "json2mq";
import useMediaQuery from "@mui/material/useMediaQuery";
import { completeGoogleLogin } from "../../apihelper/login";
import CircularProgress from "@mui/material/CircularProgress";
import SidebarMenuList from "../../includes/sidebarMenuList.jsx";
import  PortFolioGallery  from "../home/PortFolioGallery.js";
import  BestCryptos  from "../home/bestcryptos.js";
import { useLocation } from "react-router-dom";
import {  DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { addToPortfolio,RenamePortfolio,updateProfilePhoto } from "../../apihelper/portfolio";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Alert from "@mui/material/Alert";
import { useParams } from "react-router-dom";
import {
  
  getTotalunit,
  getFetchdropdown,
} from "../../apihelper/cryptocurrencylist";
import {
  getCryporCurrencyListHome,

} from "../../apihelper/homepage";
import Dialog from "@mui/material/Dialog";
const drawerWidth = 292;
// 
export default function HiddenGems(props) {
  const {filter} = props;

  //console.log("Props: ",props);
  const showPopup = props.showPopup;

 // Check if referral ID exists in URL
const urlParams = new URLSearchParams(window.location.search);
let referrerId = urlParams.get('ref');

// If referral ID is not in the URL, check localStorage
if (!referrerId) {
  referrerId = localStorage.getItem('referrerId');
}

// Process the referrerId during registration/login
if (referrerId) {
  
  console.log(`Referred by user: ${referrerId}`);
  // You can now send this referral ID to your backend during the login or signup process
}

  const [selectedmenu, setselectedmenu] = useState("");

  const [options, setOptions] = useState([]);
  const [totalUnits, setTotalUnits] = useState(0);
 
  const [Usablewallet, setUsablewallet] = useState("");
  const [selectedOption, setSelectedOption] = useState([]);
  const [user_role, setuser_role] = useState("");
  const location = useLocation(); 
  const queryString = location.search; // This includes the entire query string starting with '?'
  console.log("Query String: ", queryString);

  const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const { window1 } = props;
  const [alignment, setAlignment] = React.useState("left");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [showloginpage, setShowloginpage] = useState(false);
 
 
  const loc = location.pathname;
    
 
  const [loadingForLogin, setLoadingForLogin] = useState(false);

  const [loaderForSymbolLink, setLoaderForSymbolLink] = useState(false);

  const handleClose = () => setOpen(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [open, setOpen] =useState(false);
  const [unit, setUnit] = useState("");
  const [buySell, setbuySell] = React.useState("");
  const [price, setPrice] = useState("");
  const [selectedDate, handleDateChange] = useState("");
  const [portfolioTypeId, setPortfolioTypeId] = useState(null);
  const [msgforUpdatePortfolio, setMsgforUpdatePortfolio] =
    useState("loading...");
  const [severity, setSeverity] = useState("info");
  const [dropDown, setdropDown] = useState([]);
  const [newOptionValue, setNewOptionValue] = useState('');
  const [slugpopupdata, setSlugpopupdata] = useState({
      slugname: "",
      slug: "",
      units: 0,
    });

  const [dataslug, setdataslug] = useState('');
  const [snackbaropen, setSnackbaropen] = useState(false);

   useEffect(() => {
      fetchDropdownOptions();
    }, []);

    const fetchDropdownOptions = async () => {
          const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
      
          getFetchdropdown({
            user_id: userData?.user_id,
      
            Token: userData?.token,
          }).then((res) => {
            if (res.code === "200") {
              const newOptions = res.data.map((item) => ({
                value: item.id,
                label: item.name,
              }));
              setOptions(newOptions);
              setuser_role(res.user_role);
              //console.log(res.user_role);
              // Set the last option as the default selected option
            if (newOptions.length > 0) {
              setSelectedOption(newOptions[newOptions.length - 1])
              handleChange(newOptions[newOptions.length - 1], slugpopupdata.slugname);
            }
            } else {
              console.log("Data fething error");
            }
          });
        };

    const handleCreate = async (inputValue) => {

      if (options.find(option => option.value === inputValue)) {
        return; // Exit early if the option already exists
      }
      const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
      //console.log(inputValue);
      const newOption = { value: inputValue, label: inputValue };
      const newOptions = [...options, newOption]; // Create a new array with the existing options plus the new one
      setOptions(newOptions); // Update the options state
      const createdOption = newOptions.find(option => option.value == inputValue);
     // console.log(createdOption);
      if (createdOption) {
        handleChange(createdOption);
      }
     
    };

    const handleChange2 = (e) => {
      const value = e.target.value;
      // Check if the input contains a hyphen
      if (value.includes('-')) {
        setShowAlert(true); // Show the alert if there's a hyphen
      } else {
        setShowAlert(false); // Hide the alert if there's no hyphen
        setNewOptionValue(value); // Update the state with the new value
      }
    };
    const handleDialogSubmit = () => {
        // Handle submitting the dialog (e.g., creating the new option)
        console.log("Creating new option:", newOptionValue);
    
        // Update options state with the new option
        const newOption = { value: newOptionValue, label: newOptionValue };
        setOptions([...options, newOption]);
        setSelectedOption(newOption);
        // Close the dialog
        setIsDialogOpen(false);
    
        // setSelectedOption(selectedOption);
        const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
        getTotalunit({
          user_id: userData.user_id,
          slug: dataslug, // Assuming value contains the slug
          portfolio_type_id: newOption.value, // Adjust as needed
          Token: userData?.token,
        }).then((res) => {
          if (res.code === "200") {
            setTotalUnits(res.total_uint);
            setNewOptionValue("");
            setUsablewallet(res.wallet_amount);
          } else {
            console.log("Data fething error");
          }
          // console.log(slugdata)
        });
      };
      const closesnackbar = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
    
        setSnackbaropen(false);
      };
    const formatToNonZero = (number) => {
      number = parseFloat(number);
      
      if (number >= 1) {
        return number.toFixed(2);
      } else if (number >= 0.1) {
        return number.toFixed(1);
      } else if (number >= 0.01) {
        return number.toFixed(4);
      } else if (number >= 0.001) {
        return number.toFixed(5);
      } else if (number >= 0.0001) {
        return number.toFixed(6);
      } else if (number >= 0.00001) {
        return number.toFixed(7);
      } else if (number >= 0.000001) {
        return number.toFixed(8);
      } else if (number >= 0.0000001) {
        return number.toFixed(9);
      } else if (number >= 0.00000001) {
        return number.toFixed(10);
      } else if (number >= 0.000000001) {
        return number.toFixed(11);
      }
      return number.toFixed(1);
    };

    const handleClick = (buySell) => {
        setSnackbaropen(true);
        setOpen(true);
        setSeverity("info");
        setIsFormSubmitted(false);
        // setSnackbarcolor("success");
        // setSnackbarmessage("Data saved successfully");
        setMsgforUpdatePortfolio("loading...");
        //console.log(price, selectedDate, buySell, unit);
        const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
        const portfolioId =
          selectedOption && !isNaN(parseInt(selectedOption.value))
            ? parseInt(selectedOption.value)
            : 0;
        const existingPortfolio = portfolioId > 0 ? "yes" : "no"; // Determine existing_portfolio based on portfolioId
    
        addToPortfolio({
          portfolio_type_id: portfolioId,
          portfolio_name: selectedOption ? selectedOption.label : "", // Ensure selectedOption is defined
    
          existing_portfolio: existingPortfolio,
          user_id: userData.user_id,
          slug: slugpopupdata.slugname,
          unit: unit,
          acquisition_price: price,
          date_time: selectedDate,
          type: buySell,
          Token: userData?.token,
        }).then((res) => {
          if (res.code === "200") {
            // setOpen(false);
            setSeverity("info");
            //console.log(res.message);
            setMsgforUpdatePortfolio(res.message);
            setPortfolioTypeId(res.portfolio_type_id);
            setIsFormSubmitted(true);
            // navigate(
            //   `/my-portfolio/${res.portfolio_type_id}/${encodeURIComponent(
            //     selectedOption.label
            //   )}`
            // );
          } else {
            setSeverity("error");
            // setOpen(true)
            setMsgforUpdatePortfolio(res.message);
          }
        });
      };


      const handleAddToPortfolio = (idx) => {
        console.log("IDX value is : ", idx);
      
        // Check if userData exists before making the API call
        const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
        if (!userData) {
          const originalUrl = '/audit-my-portfolio';
          sessionStorage.setItem("originalUrl", originalUrl);
          console.log("original url: " + originalUrl);
          window.history.pushState({}, "", "/login");
          setLoginModalOpen(true);
          return; // Exit the function if userData is not present
        }
      
        console.log("User Data is there");
      
        // If userData exists, proceed with fetching cryptocurrency data
        getCryporCurrencyListHome({
          slug: idx,
        }).then((res) => {
          if (res.code === "200") {
            console.log("data got successfully", res.data);
      
            // Update slugdata
            setuser_role(res.user_role);
      
            // Use res.data directly here
            setSlugpopupdata({
              slugname: res.data[0].slug,
              slug: res.data[0].name,
              units: res.data[0].total_unit,
              pricetoday: res.data[0].price_today,
              logo: res.data[0].logo,
            });
            console.log("Slug Popup Data : ", res.data);
            setOpen(true);
          } else {
            console.log("Data fetching error");
          }
        });
      };

  const mediaQueryVar = useMediaQuery(
    json2mq({
      minWidth: 900,
    })
  );

  const mediaQueryVar5 = useMediaQuery(
    json2mq({
      minWidth: 1900,
    })
  );
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

 
 const handleChange = async (selectedOption, slug) => {
     if (selectedOption && selectedOption._isNew_) {
       setIsDialogOpen(true);
       console.log("Creating new option:", selectedOption.value);
       setSelectedOption(null);
       setdataslug(slug);
     } else if (!selectedOption) {
       // Handle the case when the selection is cleared
       setSelectedOption(null);
       setShowAlert(false);
       // Perform any other necessary actions
       return;
     } else {
 
       const selectedValue = selectedOption.value;
       if (selectedValue.includes('-')) {
         setErrorMessage('Hyphens are not allowed in the option.'); // Set error message
         setShowAlert(true); // Show the alert
         return; // Abort further processing
       }
       setShowAlert(false);
       setSelectedOption(selectedOption);
       const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
       getTotalunit({
         user_id: userData.user_id,
         slug: slug, // Assuming value contains the slug
         portfolio_type_id: selectedOption.value, // Adjust as needed
         Token: userData?.token,
       }).then((res) => {
         if (res.code === "200") {
           setTotalUnits(res.total_uint);
           setUsablewallet(res.wallet_amount);
         } else {
           console.log("Data fething error");
         }
         // console.log(slugdata)
       });
     }
   };
   
  const drawer = (
    <div className="d-flex flex-column h-100" style={{ overflowY: "hidden" }}>
      <SidebarMenuList />
    </div>
  );
 
  useEffect(() => {
    setShowloginpage(true);
    const search = window.location.search;
    const params = new URLSearchParams(search);
   // console.log(params);
    if (params) {
      const googleLoginCode = params.get("code");
      if (googleLoginCode) {
        setLoadingForLogin(true);
        completeGoogleLogin(googleLoginCode).then((data) => {
         // console.log(data);
          if (data.code == "200") {
           // console.log(data.message);
            localStorage.setItem(
              "cw_portfolio_user",
              JSON.stringify(data.data)
            );
            const portfolio_userId = localStorage.getItem("portfolio_userId");
           // console.log("portfolio_UserID: ", portfolio_userId);
            if (portfolio_userId) {
             // console.log("login userId: ",portfolio_userId);
              //  navigate(`portfolio/${portfolio_userId}`, { replace: true });
              window.location.replace(
                `${process.env.REACT_APP_HOME_URL}portfolio/${portfolio_userId}`
              );
              localStorage.removeItem("portfolio_userId");
            } else {
              if (data.data.portfolio_form_redirect === "yes") {
                //navigate("/profile", { replace: true });
                window.location.replace(
                  `${process.env.REACT_APP_HOME_URL}profile`
                );
              } else {
                window.location.replace(
                  `${process.env.REACT_APP_HOME_URL}home`
                );
                //navigate("/cryptocurrencylist", { replace: true });
              }
            }
          } else {
            console.log("GOOGLE LOGIN FAILED");
            // TODO
            // pop up the error
          }
        });
      } else {
        setShowloginpage(false);
      }
    }
  });




 

  var rows = [],
    i = 0,
    len = 15;
  while (++i <= len) rows.push(i);

  const container =
    window1 !== undefined ? () => window1().document.body : undefined;
    const [selectedButton, setSelectedButton] = useState("breakoutStars");
  const [isTableView, setIsTableView] = useState(true);
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);

  useEffect(() => {
    if (showPopup) {
      setLoginModalOpen(true); // Open the login modal
      localStorage.setItem("isPopupManuallyOpened", "true");
    }
  }, [showPopup]);
  

  const handleLoginClick = () => {
    // Mask the URL by adding /login at the end without navigating
    window.history.pushState({}, "", "/login");
    localStorage.setItem("isPopupManuallyOpened", "true");
    // Open the login modal
    setLoginModalOpen(true);
  };

  const handleModalClose = () => {
    // Close the modal and reset the URL to the current page
    setLoginModalOpen(false);
    navigate("/portfolio-gallery", { replace: true });
};




  const handleButtonClick = (buttonName) => {
    // If the same button is clicked again, don't toggle the view
    if (selectedButton === buttonName) {
      return;
    }

    // Toggle the view based on the clicked button
    setSelectedButton(buttonName);
    setIsTableView(buttonName === "breakoutStars" ? false : true); 
  };



  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    // Check if the query string starts with ?login
    if (queryString.startsWith("?login") && !userData) {
      // Call the handleLoginClick function after 6 seconds
      setTimeout(() => {
        handleLoginClick();
      }, 6000);
    }
  }, [queryString]);


 const [cryptoData, setCryptoData] = useState([]);
 const [loading, setLoading] = useState(true);

 useEffect(() => {
  const url = `https://crowdwisdom.live/wp-json/api/v1/hiddenGems?filter=${encodeURIComponent(filter)}`;

  fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      setCryptoData(Array.isArray(data) ? data : []);
      setLoading(false);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
      setLoading(false);
    });
}, [filter]);
// Runs whenever the filter changes

  
 const SkeletonLoader = () => (
  <>
    {Array.from({ length: 6 }).map((_, index) => (
      <tr key={index} style={{ background: "#fff", border: "1px solid #1877F2", borderRadius: "10px", marginBottom: "10px", padding: "6px", height: "60px" }}>
        {Array.from({ length: 6 }).map((_, idx) => (
          <td
            key={idx}
            style={{
              padding: "12px",
              textAlign: "center",
              fontSize: "14px",
              border: "1px solid #1877F2",
              borderRadius: idx === 0 ? "20px 0 0 20px" : idx === 5 ? "0 20px 20px 0" : "0",
              borderLeft: idx === 0 ? "none" : "",
              borderRight: idx === 4 ? "none" : "",
            }}
          >
            <div className="skeleton-box" />
          </td>
        ))}
      </tr>
    ))}
  </>
);


 
  return (

    <>
    {mediaQueryVar === true ? (

    
    
    <div style={{ width: "100%", margin: "auto", fontFamily: "Arial, sans-serif", overflowX: "auto" }}>

<Modal
  sx={{ overflow: "hidden" }}
  open={open}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <div className="CardOpener" style={{ overflow: "hidden" }}>
    {isFormSubmitted ? (
      <Card className="card__container">
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CheckCircleOutlineIcon sx={{ fontSize: 50, mr: 1 }} />
          </div>
          <Typography
            severity="success"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Portfolio added successfully!
          </Typography>
        </>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <Button
            onClick={() => {
              setIsFormSubmitted(false);
              setOpen(false);
              setUnit("");
              setSelectedOption("");
              setPrice("");
              setTotalUnits(0);
              handleDateChange("");
              setUsablewallet("");
            }}
            color="primary"
            style={{
              backgroundColor: "rgba(67, 97, 238, 0.15)",
              borderRadius: "6px",
              border: "none",
              textTransform: "none",
              borderStyle: "solid",
              borderColor: "rgba(67, 97, 238, 0.15)",
              borderWidth: "1px",
              color: "#4361ee",
              whiteSpace: "nowrap",
              float: "inline-end",
              width: mediaQueryVar === true ? "150px" : "100px",
              height: mediaQueryVar === false ? "30px" : "",
              fontSize: mediaQueryVar === false ? "10px" : "11px",
            }}
          >
            Add More Cryptos
          </Button>
          <Button
            onClick={() =>
              navigate(
                `/my-portfolio/${portfolioTypeId}/${encodeURIComponent(
                  selectedOption.label
                )}`
              )
            }
            variant="outlined"
            style={{
              backgroundColor: "rgba(67, 97, 238, 0.15)",
              borderRadius: "6px",
              border: "none",
              textTransform: "none",
              borderStyle: "solid",
              borderColor: "rgba(67, 97, 238, 0.15)",
              borderWidth: "1px",
              color: "#4361ee",
              whiteSpace: "nowrap",
              float: "inline-end",
              width: mediaQueryVar === true ? "100px" : "100px",
              height: mediaQueryVar === false ? "30px" : "",
              fontSize: mediaQueryVar === false ? "10px" : "11px",
            }}
            color="primary"
          >
            View Portfolio
          </Button>
        </div>
      </Card>
    ) : (
      <Dialog
        PaperProps={{
          sx: {
            position: mediaQueryVar ? 'static' : 'fixed',
            bottom: mediaQueryVar ? '' : 0,
            left: mediaQueryVar ? '' : 0,
            right: mediaQueryVar ? '' : 0,
            margin: '0',
            maxWidth: '100%',
            minHeight: '50%', // Adjust max height if needed
            borderRadius: mediaQueryVar ? "24px" : "24px 24px 0 0",
            padding: "10px",
            alignItems: "center",
            overflow: "scroll", 
            '&::-webkit-scrollbar': {
              display: 'none',
              },
               '-ms-overflow-style': 'none',  
               'scrollbar-width': 'none'// Ensure no scrollbar
          },
        }}
        open={open}
        onClose={handleClose}
      >
        <div style={{ padding: '20px', width: mediaQueryVar===false?'100%': '400px', margin: '0' ,marginTop:"-5px",}}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', borderBottom:"1px #E3E3E3 solid" }}>
            <Typography 
            style={{ fontWeight: '500', fontSize: '20.767px', paddingLeft: "25%" }} gutterBottom>
              Add To Portfolio
            </Typography>
            

            <div className="primaryButton">
              <img
                className="frameIcon"
                alt=""
                onClick={handleClose}
                src={window.constants.asset_path + '/images/frame17.svg'}
                style={{ background: "#F3F3F3", width: "25px", height: "25px", 
                }}
              />
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '5px' ,marginLeft: mediaQueryVar === false ? '5px' : ''}}>
            <Typography style={{ fontSize: "13.845px", fontWeight: '500', marginBottom: '1px', color: 'black',marginTop:"-8px" }}>
              Select Portfolio
            </Typography>
            <div style={{ marginTop: '5px' }}>
              <CreatableSelect
                isClearable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: '12px',
                    border: '1px solid #1877F2',
                    boxShadow: 'none',
                    minHeight: '40px',
                    '&:hover': {
                      borderRadius: '12px',
                      border: '2px solid #1877F2',
                    },
                  }),
                  menu: (provided) => ({
                    ...provided,
                    borderRadius: '8px',
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: '',
                  }),
                  dropdownIndicator: (provided) => ({
                    ...provided,
                    color: '#1877F2',
                    border: "1px solid #1877F2",
                    padding: "5px",
                    margin: "8px",
                    borderRadius: "3.786px",
                    '&:hover': {
                      color: '#1877F2',
                    },
                  }),
                  indicatorSeparator: (provided) => ({
                    display: 'none',
                  }),
                }}
                placeholder=""
                onChange={(options) => handleChange(options, slugpopupdata.slugname)}
                onCreateOption={handleCreate}
                options={[
                  { value: '', label: 'Create new portfolio', _isNew_: true }, // Dynamic "create new option" item
                  ...options // Existing options
                ]}
                value={selectedOption}
              />
            </div>
            {showAlert && (
        <Alert severity="error" sx={{ marginBottom: '10px' }}>
          {errorMessage}
        </Alert>
        
      )}
          </div>
          <Typography style={{ fontSize: '13.845px', fontWeight: '500', color: '#000', marginTop: '10px', marginBottom: '5px' }}>
          Usable Wallet Value: {Usablewallet}
          </Typography>
          <div style={{ padding: "8px", background: '#F3F3F3', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' }}>
            <div style={{ display: 'flex', alignItems: 'center', flex: 0, minWidth: 0 }}>
              <img
                style={{ marginRight: '10px', border: "2px solid #000", borderRadius: "19px", height: "27.69px", width: "27.69px" }}
                alt=""
                src={slugpopupdata.logo}
              />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontWeight: '500', marginBottom: '4px', fontSize: "14px", color: "#7F7F80" }}>
                  {slugpopupdata.slugname}
                </Typography>
                <Typography sx={{ fontSize: '14px', fontWeight: '500', color: 'black', margin: '0' }}>
                  {slugpopupdata.slug}
                </Typography>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '20px', flex: 1 }}>
              <Typography sx={{ fontWeight: '500', marginBottom: '4px', fontSize: "13px", color: "#7F7F80" }}> Price</Typography>
              <Typography sx={{ fontSize: '12.114px', fontWeight: '500', color: 'black', margin: '0' }}>
                {slugpopupdata.pricetoday}
              </Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '20px' }}>
              <Typography sx={{ fontWeight: '500', marginBottom: '4px', fontSize: "13px", color: "#7F7F80" }}> Units</Typography>
              <Typography sx={{ fontSize: '12.114px', fontWeight: '500', color: 'black', margin: '0' }}>
                {totalUnits}
              </Typography>
            </div>
          </div>
          <div className="card__container__row3">
            <div style={{marginLeft:"-5px"}}>
              <p style={{ fontSize: "14px", fontWeight: "500", }}>Enter number of Units</p>
              <input
                style={{ backgroundColor:"white",color:"black",padding: "20px", border: "1px solid #1877F2", borderRadius: "10.384px", width: "100%", height: "45px",margin:0 }}
                min="1"
                max="10"
                type="number"
                placeholder=""
                value={unit}
                onChange={(e) => setUnit(e.target.value)}
              ></input>
            </div>
            <div>
            ${formatToNonZero(
  unit * parseFloat((slugpopupdata.pricetoday || '').replace(/^\$/, ''))
)}

    </div>
            {user_role === "superuser" && (
              <>
                <div className="card__container__row3__r2">
                  <div style={{marginLeft:"-5px"}}>
                  <p  style={{ fontSize: "14px", fontWeight: "500" }}>
                    Buy/Sell Price($)
                  </p>
                  <input
                  style={{ backgroundColor:"white",color:"black",padding: "20px", border: "1px solid #1877F2", borderRadius: "10.384px", width: "100%", height: "45px" }}
                    className="card__container__row3__r2__input"
                    min="1"
                    max="10"
                    type="number"
                    placeholder="Enter Price"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  ></input></div>
                </div>
                <div style={{marginBottom:"10px",marginLeft:"-5px"}}>
                      <p style={{fontSize:"14px", fontWeight:"500"}}>Date</p>
                      <TextField
                        type="date"
                        value={selectedDate}
                        onChange={(e) => handleDateChange(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '10.384px',
                          border:"1px solid #1877F2",
                          boxShadow:"none",
                          width:"190%",
                          height:"45px",
                          
                        },
                        }}
                        />
                    </div>
              </>
            )}
            <div className="card__container__row3__r4" style={{ marginTop: "10px" }}>
              <Button
                className={
                  buySell === "buy"
                    ? "buttonToggleButActive"
                    : "buttonToggleBuy"
                }
                value="Buy"
                style={{
                  lineHeight: "20px",
                  width: "45%",
                  borderRadius: "27px",
                  border: "1px solid #1877F2",
                  padding: "15px 8px",
                  marginLeft: "5px",
                  backgroundColor: buySell === "buy" ? "" : "#1877F2",
                  color: buySell === "buy" ? "" : "white",
                  textTransform: "none",
                  fontSize: "18px",
                  fontWeight: "700"
                }}
                onClick={() => handleClick("buy")}
              >
                Add
              </Button>
              <Button
                className={
                  buySell === "sell"
                    ? "buttonToggleSellActive"
                    : "buttonToggleSell"
                }
                value="sell"
                style={{
                  lineHeight: "20px",
                  width: "45%",
                  borderRadius: "27px",
                  border: "1px solid #1877F2",
                  marginLeft: "5px",
                  marginRight:"10px",
                  padding: "15px 8px",
                  backgroundColor: buySell === "sell" ? "" : "#1877F2",
                  color: buySell === "sell" ? "" : "white",
                  textTransform: "none",
                  fontSize: "18px",
                  fontWeight: "700"
                }}
                onClick={() => handleClick("sell")}
              >
                Remove
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    )}
                </div>
              </Modal>
              <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} fullWidth maxWidth="sm">
                <DialogTitle>
                  Create New Portfolio
                  <IconButton aria-label="close"onClick={() => { setIsDialogOpen(false); setNewOptionValue(''); }}  sx={{ position: 'absolute', right: 13, top: 8 }}>
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                <div>
      <TextField
        autoFocus
        label="Enter Name"
        value={newOptionValue}
        onChange={handleChange2}
        fullWidth
        margin="dense"
      />
      {showAlert && (
        <Alert severity="error" sx={{ marginTop: '8px' }}>
          Hyphens are not allowed in the name.
        </Alert>
      )}
    </div>
        </DialogContent>
        <DialogActions sx={{marginRight:'14px'}}>
        <Button onClick={() => { setIsDialogOpen(false); setNewOptionValue(''); }} sx={{fontWeight:'bold'}}  variant="outlined" color="primary">
            Cancel
          </Button>
          <Button onClick={handleDialogSubmit} variant="contained" color="primary" sx={{fontWeight:'bold'}}disabled={!newOptionValue.trim()}>
            Submit
          </Button>
  
        </DialogActions>
        </Dialog>

        <Snackbar
          open={snackbaropen}
          autoHideDuration={5000}
          onClose={closesnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert severity={severity}>{msgforUpdatePortfolio}</Alert>
        </Snackbar>
       <style>
      {`
        @keyframes shimmer {
          0% { background-position: -200px 0; }
          100% { background-position: 200px 0; }
        }
        .skeleton-box {
          width: 80%;
          height: 20px;
          background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
          background-size: 200px 100%;
          border-radius: 4px;
          animation: shimmer 1.2s infinite linear;
        }
      `}
    </style>
          <table
              style={{
                width: "100%",
                borderCollapse: "separate",
                borderSpacing: "0 12px",
                }}
              >
            <thead>
            <tr
            style={{
            background: "linear-gradient(to right, #eef6ff, #d6eaff)",
            color: "#333",
            fontWeight: "bold",
            fontSize: "14px",
            textAlign: "left",
            }}
            >
           <th style={{ padding: "12px 16px", borderRadius: "10px 0 0 10px", width: "25%", fontWeight:"bold" }}>Crypto</th>
               <th style={{ padding: "12px", textAlign: "center", width: "12%",fontWeight:"bold" }}>Price</th>
               <th style={{ padding: "12px", textAlign: "center", width: "12%",fontWeight:"bold" }}>Last 30 Days</th>
               <th style={{ padding: "12px", textAlign: "center", width: "12%",fontWeight:"bold" }}>Last 1 Year</th>
               <th style={{ padding: "12px", textAlign: "center", width: "12%",fontWeight:"bold" }}>Date</th>
               <th style={{ padding: "12px", borderRadius: "0 10px 10px 0", width: "20%",fontWeight:"bold",  paddingLeft:"5%" }}>Grade</th>
            </tr>
            </thead>

            <tbody>
            {loading ? <SkeletonLoader /> : 
            cryptoData.length==0 ? (
              <tr>
              <td colSpan="6" style={{
                textAlign: "center",
                padding: "20px",
                fontSize: "16px",
                fontWeight: "bold",
                border: "2px solid #1877F2",
                borderRadius: "10px",
                background: "#f8f9fa",
                color: "#1877F2"
              }}>
               No Hidden Gems for this week
              </td>
            </tr>
            ):(
            cryptoData.map((coin, index) => (
            <tr
            key={index}
            style={{
              background: "#fff",
              border: "1px solid #1877F2",
              borderRadius: "10px",
              marginBottom: "10px",
              padding: "6px",
              height: "60px",
            }}
            >
            <td
            style={{ padding: "12px", textAlign: "center", fontSize: "14px",border: "1px solid #1877F2",borderTopLeftRadius:"20px",borderBottomLeftRadius:"20px", borderRight:"none" }}>
              <div style={{
                padding: "10px",
                display: "flex",
                alignItems: "center",
                fontWeight: "bold",
                fontSize: "15px",
                color: "#333",
                borderRadius: "10px",
                minWidth: "120px",
                background: "linear-gradient(91deg, rgba(154, 255, 249, 0.60) 18.86%, rgba(255, 240, 109, 0.60) 100.02%)",
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              }}
            >
              <img
                src={coin.logo}
                alt={coin.crypto}
                style={{ width: "28px", height: "28px", borderRadius: "50%", marginRight: "10px" }}
              />
              {coin.crypto}
              </div>
            </td>

            <td style={{ padding: "12px", textAlign: "center", fontSize: "14px",border: "1px solid #1877F2",borderLeft:"none", borderRight:"none" }}>{coin.price}</td>

            <td style={{ padding: "12px", textAlign: "center", fontSize: "14px",border: "1px solid #1877F2",borderLeft:"none", borderRight:"none"  }}>{coin.Thirtyd_change}</td>

            <td style={{ padding: "12px", textAlign: "center", fontSize: "14px",border: "1px solid #1877F2", borderLeft:"none", borderRight:"none" }}>{coin.Year_change}</td>

            <td style={{ padding: "12px", textAlign: "center", fontSize: "14px",border: "1px solid #1877F2", borderLeft:"none", borderRight:"none" }}>{coin.recorded_on}</td>

            <td
              style={{
                padding: "12px",
                border: "1px solid #1877F2", 
                borderTopRightRadius:"20px",
                borderBottomRightRadius:"20px", 
                borderLeft:"none"
              }}
            >
              <div style={{ 
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",

                }}>
              <span
  style={{
    backgroundColor:
    coin.Norm_grade === "Outperformer" || coin.Norm_grade === "Emerging Outperformer"
      ? "#199F5F"
      : coin.Norm_grade === "Moderate Performer"
      ? "#D4AF37" // Dark Gold
      : coin.Norm_grade === "Underperformer"
      ? "#FF4141" // Dark Red (Maroon)
      : "gray",
     color: "white",
     padding: "5px 12px",
     borderRadius: "15px",
     fontSize: "12px",
     fontWeight: "bold",
     minWidth:"140px",
     textAlign:"center"
  }}
>
  {coin.Norm_grade === "Emerging Outperformer" ? "Outperformer" : coin.Norm_grade}
</span>


              <button
               onClick={() => handleAddToPortfolio(coin.crypto)}
                style={{
                  background: "none",
                  border: "2px solid #0084ff",
                  borderRadius: "50%",
                  width: "26px",
                  height: "26px",
                  color: "#0084ff",
                  fontSize: "16px",
                  fontWeight: "bold",
                  cursor: "pointer",
                  marginLeft: "10px",
                }}
              >
                +
              </button>
              </div>
            </td>
            </tr>
            ))

            )}
            </tbody>
            </table>
    </div>
 
    ) : (


    <div style={{ width: "100%", margin: "auto", marginTop:"10px",marginBottom:"70px" }}>

<Modal
  sx={{ overflow: "hidden" }}
  open={open}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <div className="CardOpener" style={{ overflow: "hidden" }}>
    {isFormSubmitted ? (
      <Card className="card__container">
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CheckCircleOutlineIcon sx={{ fontSize: 50, mr: 1 }} />
          </div>
          <Typography
            severity="success"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Portfolio added successfully!
          </Typography>
        </>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <Button
            onClick={() => {
              setIsFormSubmitted(false);
              setOpen(false);
              setUnit("");
              setSelectedOption("");
              setPrice("");
              setTotalUnits(0);
              handleDateChange("");
              setUsablewallet("");
            }}
            color="primary"
            style={{
              backgroundColor: "rgba(67, 97, 238, 0.15)",
              borderRadius: "6px",
              border: "none",
              textTransform: "none",
              borderStyle: "solid",
              borderColor: "rgba(67, 97, 238, 0.15)",
              borderWidth: "1px",
              color: "#4361ee",
              whiteSpace: "nowrap",
              float: "inline-end",
              width: mediaQueryVar === true ? "150px" : "100px",
              height: mediaQueryVar === false ? "30px" : "",
              fontSize: mediaQueryVar === false ? "10px" : "11px",
            }}
          >
            Add More Cryptos
          </Button>
          <Button
            onClick={() =>
              navigate(
                `/my-portfolio/${portfolioTypeId}/${encodeURIComponent(
                  selectedOption.label
                )}`
              )
            }
            variant="outlined"
            style={{
              backgroundColor: "rgba(67, 97, 238, 0.15)",
              borderRadius: "6px",
              border: "none",
              textTransform: "none",
              borderStyle: "solid",
              borderColor: "rgba(67, 97, 238, 0.15)",
              borderWidth: "1px",
              color: "#4361ee",
              whiteSpace: "nowrap",
              float: "inline-end",
              width: mediaQueryVar === true ? "100px" : "100px",
              height: mediaQueryVar === false ? "30px" : "",
              fontSize: mediaQueryVar === false ? "10px" : "11px",
            }}
            color="primary"
          >
            View Portfolio
          </Button>
        </div>
      </Card>
    ) : (
      <Dialog
        PaperProps={{
          sx: {
            position: mediaQueryVar ? 'static' : 'fixed',
            bottom: mediaQueryVar ? '' : 0,
            left: mediaQueryVar ? '' : 0,
            right: mediaQueryVar ? '' : 0,
            margin: '0',
            maxWidth: '100%',
            minHeight: '50%', // Adjust max height if needed
            borderRadius: mediaQueryVar ? "24px" : "24px 24px 0 0",
            padding: "10px",
            alignItems: "center",
            overflow: "scroll", 
            '&::-webkit-scrollbar': {
              display: 'none',
              },
               '-ms-overflow-style': 'none',  
               'scrollbar-width': 'none'// Ensure no scrollbar
          },
        }}
        open={open}
        onClose={handleClose}
      >
        <div style={{ padding: '20px', width: mediaQueryVar===false?'100%': '400px', margin: '0' ,marginTop:"-5px",}}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', borderBottom:"1px #E3E3E3 solid" }}>
            <Typography 
            style={{ fontWeight: '500', fontSize: '20.767px', paddingLeft: "25%" }} gutterBottom>
              Add To Portfolio
            </Typography>
            

            <div className="primaryButton">
              <img
                className="frameIcon"
                alt=""
                onClick={handleClose}
                src={window.constants.asset_path + '/images/frame17.svg'}
                style={{ background: "#F3F3F3", width: "25px", height: "25px", 
                }}
              />
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '5px' ,marginLeft: mediaQueryVar === false ? '5px' : ''}}>
            <Typography style={{ fontSize: "13.845px", fontWeight: '500', marginBottom: '1px', color: 'black',marginTop:"-8px" }}>
              Select Portfolio
            </Typography>
            <div style={{ marginTop: '5px' }}>
              <CreatableSelect
                isClearable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: '12px',
                    border: '1px solid #1877F2',
                    boxShadow: 'none',
                    minHeight: '40px',
                    '&:hover': {
                      borderRadius: '12px',
                      border: '2px solid #1877F2',
                    },
                  }),
                  menu: (provided) => ({
                    ...provided,
                    borderRadius: '8px',
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: '',
                  }),
                  dropdownIndicator: (provided) => ({
                    ...provided,
                    color: '#1877F2',
                    border: "1px solid #1877F2",
                    padding: "5px",
                    margin: "8px",
                    borderRadius: "3.786px",
                    '&:hover': {
                      color: '#1877F2',
                    },
                  }),
                  indicatorSeparator: (provided) => ({
                    display: 'none',
                  }),
                }}
                placeholder=""
                onChange={(options) => handleChange(options, slugpopupdata.slugname)}
                onCreateOption={handleCreate}
                options={[
                  { value: '', label: 'Create new portfolio', _isNew_: true }, // Dynamic "create new option" item
                  ...options // Existing options
                ]}
                value={selectedOption}
              />
            </div>
            {showAlert && (
        <Alert severity="error" sx={{ marginBottom: '10px' }}>
          {errorMessage}
        </Alert>
        
      )}
          </div>
          <Typography style={{ fontSize: '13.845px', fontWeight: '500', color: '#000', marginTop: '10px', marginBottom: '5px' }}>
          Usable Wallet Value: {Usablewallet}
          </Typography>
          <div style={{ padding: "8px", background: '#F3F3F3', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' }}>
            <div style={{ display: 'flex', alignItems: 'center', flex: 0, minWidth: 0 }}>
              <img
                style={{ marginRight: '10px', border: "2px solid #000", borderRadius: "19px", height: "27.69px", width: "27.69px" }}
                alt=""
                src={slugpopupdata.logo}
              />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontWeight: '500', marginBottom: '4px', fontSize: "14px", color: "#7F7F80" }}>
                  {slugpopupdata.slugname}
                </Typography>
                <Typography sx={{ fontSize: '14px', fontWeight: '500', color: 'black', margin: '0' }}>
                  {slugpopupdata.slug}
                </Typography>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '20px', flex: 1 }}>
              <Typography sx={{ fontWeight: '500', marginBottom: '4px', fontSize: "13px", color: "#7F7F80" }}> Price</Typography>
              <Typography sx={{ fontSize: '12.114px', fontWeight: '500', color: 'black', margin: '0' }}>
                {slugpopupdata.pricetoday}
              </Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '20px' }}>
              <Typography sx={{ fontWeight: '500', marginBottom: '4px', fontSize: "13px", color: "#7F7F80" }}> Units</Typography>
              <Typography sx={{ fontSize: '12.114px', fontWeight: '500', color: 'black', margin: '0' }}>
                {totalUnits}
              </Typography>
            </div>
          </div>
          <div className="card__container__row3">
            <div style={{marginLeft:"-5px"}}>
              <p style={{ fontSize: "14px", fontWeight: "500", }}>Enter number of Units</p>
              <input
                style={{ backgroundColor:"white",color:"black",padding: "20px", border: "1px solid #1877F2", borderRadius: "10.384px", width: "100%", height: "45px",margin:0 }}
                min="1"
                max="10"
                type="number"
                placeholder=""
                value={unit}
                onChange={(e) => setUnit(e.target.value)}
              ></input>
            </div>
            <div>
            ${formatToNonZero(
  unit * parseFloat((slugpopupdata.pricetoday || '').replace(/^\$/, ''))
)}

    </div>
            {user_role === "superuser" && (
              <>
                <div className="card__container__row3__r2">
                  <div style={{marginLeft:"-5px"}}>
                  <p  style={{ fontSize: "14px", fontWeight: "500" }}>
                    Buy/Sell Price($)
                  </p>
                  <input
                  style={{ backgroundColor:"white",color:"black",padding: "20px", border: "1px solid #1877F2", borderRadius: "10.384px", width: "100%", height: "45px" }}
                    className="card__container__row3__r2__input"
                    min="1"
                    max="10"
                    type="number"
                    placeholder="Enter Price"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  ></input></div>
                </div>
                <div style={{marginBottom:"10px",marginLeft:"-5px"}}>
                      <p style={{fontSize:"14px", fontWeight:"500"}}>Date</p>
                      <TextField
                        type="date"
                        value={selectedDate}
                        onChange={(e) => handleDateChange(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '10.384px',
                          border:"1px solid #1877F2",
                          boxShadow:"none",
                          width:"190%",
                          height:"45px",
                          
                        },
                        }}
                        />
                    </div>
              </>
            )}
            <div className="card__container__row3__r4" style={{ marginTop: "10px" }}>
              <Button
                className={
                  buySell === "buy"
                    ? "buttonToggleButActive"
                    : "buttonToggleBuy"
                }
                value="Buy"
                style={{
                  lineHeight: "20px",
                  width: "45%",
                  borderRadius: "27px",
                  border: "1px solid #1877F2",
                  padding: "15px 8px",
                  marginLeft: "5px",
                  backgroundColor: buySell === "buy" ? "" : "#1877F2",
                  color: buySell === "buy" ? "" : "white",
                  textTransform: "none",
                  fontSize: "18px",
                  fontWeight: "700"
                }}
                onClick={() => handleClick("buy")}
              >
                Add
              </Button>
              <Button
                className={
                  buySell === "sell"
                    ? "buttonToggleSellActive"
                    : "buttonToggleSell"
                }
                value="sell"
                style={{
                  lineHeight: "20px",
                  width: "45%",
                  borderRadius: "27px",
                  border: "1px solid #1877F2",
                  marginLeft: "5px",
                  marginRight:"10px",
                  padding: "15px 8px",
                  backgroundColor: buySell === "sell" ? "" : "#1877F2",
                  color: buySell === "sell" ? "" : "white",
                  textTransform: "none",
                  fontSize: "18px",
                  fontWeight: "700"
                }}
                onClick={() => handleClick("sell")}
              >
                Remove
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    )}
                </div>
              </Modal>
              <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} fullWidth maxWidth="sm">
                <DialogTitle>
                  Create New Portfolio
                  <IconButton aria-label="close"onClick={() => { setIsDialogOpen(false); setNewOptionValue(''); }}  sx={{ position: 'absolute', right: 13, top: 8 }}>
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                <div>
      <TextField
        autoFocus
        label="Enter Name"
        value={newOptionValue}
        onChange={handleChange2}
        fullWidth
        margin="dense"
      />
      {showAlert && (
        <Alert severity="error" sx={{ marginTop: '8px' }}>
          Hyphens are not allowed in the name.
        </Alert>
      )}
    </div>
        </DialogContent>
        <DialogActions sx={{marginRight:'14px'}}>
        <Button onClick={() => { setIsDialogOpen(false); setNewOptionValue(''); }} sx={{fontWeight:'bold'}}  variant="outlined" color="primary">
            Cancel
          </Button>
          <Button onClick={handleDialogSubmit} variant="contained" color="primary" sx={{fontWeight:'bold'}}disabled={!newOptionValue.trim()}>
            Submit
          </Button>
  
        </DialogActions>
        </Dialog>

        <Snackbar
          open={snackbaropen}
          autoHideDuration={5000}
          onClose={closesnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert severity={severity}>{msgforUpdatePortfolio}</Alert>
        </Snackbar>
        
       {loading ? (
        // Skeleton Loader (5 placeholder cards)
        Array.from({ length: 5 }).map((_, index) => (
          <div
            key={index}
            style={{
              background: "white",
              borderRadius: "12px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              marginBottom: "15px",
              padding: "0px",
              border: "1px solid #1877F2",
              position: "relative",
              overflow: "hidden",
            }}
          >
            {/* Shimmer effect */}
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: "linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.2) 50%, rgba(255,255,255,0) 100%)",
                animation: "shimmer 1.5s infinite",
              }}
            ></div>

            {/* Placeholder Content */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontWeight: "bold",
                fontSize: "16px",
                color: "#333",
                background: "var(--try-gradient, linear-gradient(90deg, rgba(24, 119, 242, 0.17) 0.02%, rgba(24, 119, 242, 0.00) 123.27%))",
                borderRadius: "28px",
                padding: "10px",
              }}
            >
              <div
                style={{
                  width: "28px",
                  height: "28px",
                  borderRadius: "50%",
                  background: "#e0e0e0",
                  marginRight: "10px",
                }}
              ></div>
              <div
                style={{
                  width: "100px",
                  height: "16px",
                  background: "#e0e0e0",
                  borderRadius: "4px",
                }}
              ></div>
            </div>

            <div
              style={{
                width: "98%",
                height: "2px",
                background: "#1877F2",
                marginBottom: "15px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            ></div>

            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", rowGap: "10px", fontSize: "14px", marginLeft: "10px" }}>
              {Array.from({ length: 4 }).map((_, i) => (
                <div key={i} style={{ display: "flex", justifyContent: "space-between", marginRight: "10px" }}>
                  <div
                    style={{
                      width: "80px",
                      height: "14px",
                      background: "#e0e0e0",
                      borderRadius: "4px",
                    }}
                  ></div>
                  <div
                    style={{
                      width: "50px",
                      height: "14px",
                      background: "#e0e0e0",
                      borderRadius: "4px",
                    }}
                  ></div>
                </div>
              ))}
            </div>

            <button
              style={{
                display: "block",
                width: "90%",
                margin: "15px auto",
                padding: "10px",
                fontSize: "14px",
                fontWeight: "bold",
                background: "#e0e0e0",
                border: "none",
                borderRadius: "20px",
                textAlign: "center",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              }}
            ></button>
          </div>
        ))
      ) : cryptoData.length === 0 ? (
        // No Data Message
        <div
          style={{
            textAlign: "center",
            padding: "20px",
            border: "2px solid #1877F2",
            borderRadius: "12px",
            color: "#1877F2",
            fontSize: "16px",
            fontWeight: "bold",
            background: "rgba(24, 119, 242, 0.1)",
          }}
        >
         No Hidden Gems for this week
        </div>
      ) : (
                          cryptoData.map((coin, index) => (
                          <div
                          key={index}
                          style={{
                            background: "white",
                            borderRadius: "12px",
                            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                            marginBottom: "15px",
                            padding: "0px",
                            border: "1px solid #1877F2",
                          }}
                          >
                          {/* Crypto Name and Logo */}
                          <div style={{display:"flex",justifyContent:"space-between", marginBottom: "1px",alingItems:"center",
                                 background :"var(--try-gradient, linear-gradient(90deg, rgba(24, 119, 242, 0.17) 0.02%, rgba(24, 119, 242, 0.00) 123.27%))",
                                 borderRadius:"28px",
                                 padding: "10px"}}>
                             <div
                               style={{
                                 display: "flex",
                                 alignItems: "center",
                                 fontWeight: "bold",
                                 fontSize: "16px",
                                 color: "#333",
                                
                               }}
                             >
                               <img
                                 src={coin.logo}
                                 alt={coin.crypto}
                                 style={{ width: "28px", height: "28px", borderRadius: "50%", marginRight: "10px" }}
                               />
                               {coin.crypto}</div>

                               
                               <div style={{   marginRight:"10px", marginTop:"3px"}}>
                             
                             <span
                               style={{
                                backgroundColor:
                coin.Norm_grade === "Outperformer" || coin.Norm_grade === "Emerging Outperformer"
                  ? "#199F5F"
                  : coin.Norm_grade === "Moderate Performer"
                  ? "#D4AF37" // Dark Gold
                  : coin.Norm_grade === "Underperformer"
                  ? "#FF4141" // Dark Red (Maroon)
                  : "gray",
                 color: "white",
                 padding: "5px 12px",
                 borderRadius: "15px",
                 fontSize: "12px",
                 fontWeight: "bold",
                
                 textAlign:"center"
                               }}
                             >
                               {coin.Norm_grade === "Emerging Outperformer" ? "Outperformer" : coin.Norm_grade}
                             </span>
                           </div>
                             </div>
                             <div style={{width:"98%",height:"2px",background:"#1877F2",marginBottom:"15px",marginLeft:"auto",marginRight:"auto"}}></div>
   
                             {/* Left-Right Two-Column Layout */}
                             <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", rowGap: "10px", fontSize: "14px",marginLeft:"10px" }}>
                               <div style={{ display: "flex", justifyContent: "space-between" , marginRight:"5px"}}>
                                 <span>Price</span>
                                 <span style={{ fontWeight: "bold" }}>{coin.price}</span>
                               </div>
   
                               <div style={{ display: "flex", justifyContent: "space-between" , marginRight:"10px"}}>
                                 <span>Last 30 Days</span>
                                 <span style={{ fontWeight: "bold" }}>{coin.Thirtyd_change}</span>
                               </div>
   
                               <div style={{ display: "flex", justifyContent: "space-between" , marginRight:"5px"}}>
                                 <span>Last 1 Year</span>
                                 <span style={{ fontWeight: "bold" }}>{coin.Year_change}</span>
                               </div>

                               <div style={{ display: "flex", justifyContent: "space-between" , marginRight:"5px"}}>
                                 <span>Date</span>
                                 <span style={{ fontWeight: "bold" }}>{coin.recorded_on}</span>
                               </div>
   
                              
                             </div>

                          {/* Add To Portfolio Button */}
                          <button
                           onClick={() => handleAddToPortfolio(coin.crypto)}
                            style={{
                              display: "block",
                              width: "90%",
                              margin: "15px auto",
                              padding: "10px",
                              fontSize: "14px",
                              fontWeight: "bold",
                              background: "linear-gradient(91deg, rgba(154, 255, 249, 0.60) 18.86%, rgba(255, 240, 109, 0.60) 100.02%)",
                              border: "none",
                              borderRadius: "20px",
                              cursor: "pointer",
                              textAlign: "center",
                              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                              color: "black",
                              
                            }}
                          >
                            Add To Portfolio
                          </button>
                          </div>
                          )))}
    </div>

    )}
  </>
  );
}
